<template>
    <div class="tenants">
        <v-toolbar flat>
            <v-text-field
                v-model="filter"
                class="search"
                :label="$t('customers.search')"
                append-icon="far fa-search"
                clearable
                clear-icon="mdi mdi-magnify"
                single-line
                hide-details
                @input="filterTenants()"
            />

            <v-spacer />

            <v-select
                v-model="selectedFilters"
                :items="filters"
                item-text="name"
                item-value="code"
                chips
                clearable
                :label="$t('general.filters')"
                multiple
                style="max-width: 400px;"
                @change="getTenants"
            >
                <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0 || index === 1">
                        <span>{{ item.name }}</span>
                    </v-chip>

                    <span
                        v-if="index === 2"
                        class="grey--text text-caption"
                    >
                        (+{{ selectedFilters.length - 2 }} {{ $t('general.more') }})
                    </span>
                </template>
            </v-select>
        </v-toolbar>
        <!-- Tenants Table -->
        <v-data-table
            class="operations striped"
            :headers="headers"
            :items="tenants"
            :options.sync="pagination"
            :server-items-length="total"
            :footer-props="{ 'items-per-page-options': [50, 100, 200, 500] }"
            :items-per-page.sync="pagination.itemsPerPage"
            :loading="loading"
            :mobile-breakpoint="0"
        >
            <template v-slot:item="{ item, index }">
                <tr>
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.reseller ? item.reseller.name : '' }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.app }}</td>
                    <td>{{ item.venue ? item.venue.name : '' }}</td>
                    <td>{{ item.venue ? item.venue.country + ' ' + item.venue.address : '' }}</td>
                    <td v-if="isCountry('ME')">{{ item.cert_expire_at | dateFromUtc }}</td>
                    <td>{{ item.created_at | dateFromUtc }}</td>
                    <td>{{ item.trial_ends_at | dateFromUtc }}</td>
                    <td
                        class="w60"
                        align="center"
                    >
                        <v-menu
                            bottom
                            transition="slide-x-transition"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    icon
                                >
                                    <v-icon v-if="impersonatingTenantId === item.id">mdi mdi-account</v-icon>

                                    <v-icon v-else>mdi mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>

                            <v-list class="pa-0">
                                <template v-for="(option, i) in menuList">
                                    <v-divider v-if="i && option.enabled(item)" />

                                    <v-list-item
                                        :key="i"
                                        link
                                        @click="option.action(item, option.component)"
                                        v-if="option.enabled(item)"
                                    >
                                        <v-menu
                                            v-if="option.children"
                                            open-on-hover
                                            left
                                            offset-x
                                        >
                                            <template #activator="{ on, attrs }">
                                                <div
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="d-flex w-100"
                                                >
                                                    <v-list-item-action>
                                                        <v-icon>mdi mdi-chevron-left</v-icon>
                                                    </v-list-item-action>

                                                    <v-list-item-title :class="option.class">
                                                        {{ option.title(item) }}
                                                    </v-list-item-title>

                                                    <v-list-item-action>
                                                        <v-icon :class="option.class">{{ option.icon }}</v-icon>
                                                    </v-list-item-action>
                                                </div>
                                            </template>

                                            <v-list class="pa-0">
                                                <template v-for="(child, j) in option.children">
                                                    <v-divider v-if="j && child.enabled(item)" />

                                                    <v-list-item
                                                        v-if="child.enabled(item)"
                                                        :key="j"
                                                        link
                                                        @click="child.action(item, child.component)"
                                                    >
                                                        <v-list-item-action>
                                                            <v-icon :class="child.class">{{ child.icon }}</v-icon>
                                                        </v-list-item-action>

                                                        <v-list-item-title :class="child.class">
                                                            {{ child.title(item) }}
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                            </v-list>
                                        </v-menu>

                                        <template v-else>
                                            <v-list-item-title :class="option.class">
                                                {{ option.title(item) }}
                                            </v-list-item-title>

                                            <v-list-item-action>
                                                <v-icon :class="option.class">{{ option.icon }}</v-icon>
                                            </v-list-item-action>
                                        </template>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <component
            v-if="isOpen"
            v-model="isOpen"
            :is="curDialog"
            :curTenant="curTenant"
            @success="success"
        />
    </div>
</template>

<script>
import EditVenueDialog from './Components/EditVenueDialog.vue';
import EditTenantDialog from './Components/EditTenantDialog.vue';
import DeleteVenueDialog from './Components/DeleteVenueDialog.vue';
import ChangeEinvoiceApiKeyDialog from './Components/ChangeEinvoiceApiKeyDialog.vue';
import DuplicateTenantDialog from './Components/DuplicateTenantDialog.vue';
import ClearStocksDialog from './Components/ClearStocksDialog.vue';
import DeleteProductsDialog from './Components/DeleteProductsDialog.vue';
import UploadCertificateDialog from './Components/UploadCertificateDialog.vue';
import LogsDialog from './Components/LogsDialog.vue';
import PhoneNumberDialog from './Components/PhoneNumberDialog.vue';
import CertificateDialog from './Components/CertificateDialog.vue';
import ChangeTaxRatesDialog from './Components/ChangeTaxRatesDialog.vue';

import { debounce } from 'lodash';

export default {
    components: {
        EditVenueDialog,
        EditTenantDialog,
        DeleteVenueDialog,
        ChangeEinvoiceApiKeyDialog,
        DuplicateTenantDialog,
        ClearStocksDialog,
        DeleteProductsDialog,
        UploadCertificateDialog,
        LogsDialog,
        PhoneNumberDialog,
        CertificateDialog,
        ChangeTaxRatesDialog,
    },

    created() {
        this.getImpersonatedCustomerId();
    },

    data() {
        return {
            total: 0,
            pagination: {
                sortBy: ['created_at'],
                itemsPerPage: 200,
                page: 1,
                sortDesc: [true],
            },
            selectedFilters: [],
            filter: null,
            tenants: [],
            impersonatingTenantId: null,
            curTenant: null,
            curDialog: null,
            isOpen: false,
            logs: [],
            loading: false,
        };
    },

    watch: {
        pagination: {
            deep: true,
            handler() {
                this.getTenants();
            },
        },
    },

    computed: {
        headers() {
            const headers = [
                { text: this.$t('general.number'), sortable: false },
                { text: this.$t('general.name'), value: 'name' },
                { text: this.$t('general.reseller'), value: 'reseller.name' },
                { text: this.$t('general.email'), value: 'email' },
                { text: this.$t('general.app'), value: 'app' },
                { text: this.$t('general.venue'), value: 'venue.name' },
                { text: this.$t('general.adress'), value: 'venue.address' },
                {
                    text: this.$t('general.cert_expire_at'),
                    value: 'cert_expire_at',
                    visible: this.isCountry("ME")
                },
                { text: this.$t('general.created_at'), value: 'created_at' },
                { text: this.$t('general.trial_ends_at'), value: 'trial_ends_at' },
                { text: '', sortable: false }
            ]

            return headers.filter(header => header.visible !== false);
        },

        filters() {
            return [
                { name: this.$t('tenant.withoutVenueFilter'), code: "withoutVenueFilter" },
                { name: this.$t('tenant.withoutResellerFilter'), code: "withoutResellerFilter" },
                { name: this.$t('tenant.expiredContractsFilter'), code: "expiredContractsFilter" },
                { name: this.$t('tenant.withoutActiveContractsFilter'), code: "withoutActiveContractsFilter" },
                { name: this.$t('tenant.newTenantWithoutContractsFilter'), code: "newTenantWithoutContractsFilter" },
            ]
        },

        menuList() {
            return [
                {
                    title: (item = null) => this.$t('general.user'),
                    icon: 'mdi mdi-account',
                    action: () => null,
                    class: '',
                    children:
                        [{
                            title: (item = null) => this.$t('general.details'),
                            icon: 'mdi mdi-eye-outline',
                            action: this.openTenantDetails,
                            class: '',
                            component: null,
                            enabled: (item = null) => item.venue,
                        },
                        {
                            title: (item = null) => this.$t('general.phoneNumber'),
                            icon: 'mdi mdi-phone',
                            action: this.openDialog,
                            class: '',
                            component: PhoneNumberDialog,
                            enabled: (item = null) => !item.venue && item.phone,
                        },
                        {
                            title: (item = null) => this.$t('general.edit_venue'),
                            icon: 'mdi mdi-account-edit',
                            action: this.openDialog,
                            class: '',
                            component: EditVenueDialog,
                            enabled: (item = null) => item.venue,
                        },
                        {
                            title: (item = null) => this.$t('general.edit_tenant'),
                            icon: 'mdi mdi-account-cog',
                            action: this.openDialog,
                            class: '',
                            component: EditTenantDialog,
                            enabled: (item = null) => item.venue,
                        },
                        {
                            title: (item = null) => this.$t('general.duplicate_user'),
                            icon: 'mdi mdi-account-multiple-plus',
                            action: this.openDialog,
                            class: '',
                            component: DuplicateTenantDialog,
                            enabled: (item = null) => this.user.role === 'admin' || this.user.role === 'main_reseller',
                        },
                        {
                            title: (item = null) => {
                                return this.impersonatingTenantId === item.id ? this.$t('general.stop_impersonate') : this.$t('general.impersonate')
                            },
                            icon: 'mdi mdi-account-convert',
                            action: this.impersonate,
                            class: '',
                            component: null,
                            enabled: (item = null) => this.user.role === 'admin',
                        },
                        {
                            title: (item = null) => this.$t('general.logs'),
                            icon: 'mdi mdi-information',
                            action: this.openDialog,
                            class: '',
                            component: LogsDialog,
                            enabled: (item = null) => this.user.role === 'admin',
                        },
                        {
                            title: (item = null) => this.$t('general.delete'),
                            icon: 'mdi mdi-delete-outline',
                            action: this.openDialog,
                            class: 'error--text',
                            component: DeleteVenueDialog,
                            enabled: (item = null) => this.user.role === 'admin' || this.user.role === 'main_reseller',
                        }
                        ],
                    enabled: (item = null) => true,
                },
                {
                    title: (item = null) => this.$t('general.certificate'),
                    icon: 'mdi mdi-file-certificate',
                    action: () => null,
                    class: '',
                    children:
                        [
                            {
                                title: (item = null) => this.$t('general.certificate'),
                                icon: 'mdi mdi-file-certificate',
                                action: this.openDialog,
                                class: '',
                                component: CertificateDialog,
                                enabled: (item = null) => item.venue && this.isCountry('ME'),
                            },
                            {
                                title: (item = null) => this.$t('general.change_certificate'),
                                icon: 'mdi mdi-file-upload',
                                action: this.openDialog,
                                class: '',
                                component: UploadCertificateDialog,
                                enabled: (item = null) => (this.user.role === 'admin' || this.user.role === 'main_reseller' || this.isGoranOrIvanReseller()) && this.isCountry('ME'),
                            },
                            {
                                title: (item = null) => this.$t('general.change_einvoice_api_key'),
                                icon: 'mdi mdi-key-variant',
                                action: this.openDialog,
                                class: '',
                                component: ChangeEinvoiceApiKeyDialog,
                                enabled: (item = null) => (this.user.role === 'admin' || this.user.role === 'main_reseller') && this.isCountry('RS'),
                            }
                        ],
                    enabled: (item = null) => true
                },
                {
                    title: (item = null) => this.$t('general.products'),
                    icon: 'mdi mdi-cube',
                    action: () => null,
                    class: '',
                    children:
                        [
                            {
                                title: (item = null) => this.$t('general.change_tax_rates'),
                                icon: 'mdi mdi-percent',
                                action: this.openDialog,
                                class: '',
                                component: ChangeTaxRatesDialog,
                                enabled: (item = null) => item.venue,
                            },
                            {
                                title: (item = null) => this.$t('general.clear_stocks'),
                                icon: 'mdi mdi-nuke',
                                action: this.openDialog,
                                class: '',
                                component: ClearStocksDialog,
                                enabled: (item = null) => (this.user.role === 'admin' || this.user.role === 'main_reseller' || this.isGoranOrIvanReseller()),
                            },
                            {
                                title: (item = null) => this.$t('general.delete_products'),
                                icon: 'mdi mdi-delete-sweep',
                                action: this.openDialog,
                                class: 'error--text',
                                component: DeleteProductsDialog,
                                enabled: (item = null) => this.user.role === 'admin' || this.user.role === 'main_reseller' || this.user.role === 'reseller',
                            },
                        ],
                    enabled: (item = null) => true,
                },
            ]
        },
    },

    methods: {
        /**
         * Get Paginated, Filtered and Sorted tenants from server
         */
        getTenants() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

            const params = {
                page: page,
                itemsPerPage: itemsPerPage,
                sortBy: sortBy[0] ? sortBy[0] : 'created_at',
                sortOrder: sortDesc[0] ? 'desc' : 'asc',
            }

            if (this.filter) params['textFilter'] = this.filter

            // Apply search filters directly to the params object
            this.selectedFilters.forEach((filter) => params[filter] = true);

            this.loading = true;
            ApiService.get('/admin/tenants', { params: params }).then(response => {
                this.loading = false;
                this.tenants = response.data.data;
                this.total = response.data.total;
            });
        },
        /**
         * Impersonate tenant
         */
        async impersonate(item) {
            try {
                if (item.id === this.impersonatingTenantId) {

                    await ApiService.get("/admin/tenants/stop")
                    this.$snackbar(`Impersonating of the customer ${item.email} stopped`, 'success');
                    return;
                }

                const response = await ApiService.post("/admin/tenants/impersonate", { tenant_id: item.id })

                if (response.status === 200) {
                    this.$snackbar(`Impersonating of the customer ${item.email} is now active`, 'success');
                } else {
                    this.$snackbar(`Impersonating of the customer ${item.email} failed`, 'error');
                }

            } catch (error) {
                console.log(error);
                this.$snackbar(`Impersonating of the customer ${item.email} failed`, 'error');
            } finally {
                this.getImpersonatedCustomerId();
            }

        },
        /**
         * Get the id of the tenant that is currently being impersonated
         */
        async getImpersonatedCustomerId() {

            try {
                const response = await ApiService.get("/me");
                this.impersonatingTenantId = response.data.impersonating_tenant_id;
            }
            catch (error) {
                console.log(error);
                this.$snackbar('Impersonating of the customer {{email}} failed', 'error');
            }

        },
        /**
         * Open Dialog
         */
        openDialog(item, component) {
            this.curTenant = item
            this.curDialog = component
            this.isOpen = true
        },
        /**
         * On tenant update replace the current tenant in the array with the updated one
         */
        success() {
            this.getTenants();
        },
        /**
         * Filter Tenants
         */
        filterTenants: debounce(function () {
            this.getTenants();
        }, 500),
        /**
         * Open Tenant Details
         */
        openTenantDetails(item) {
            this.$router.push({ name: "tenantDetails", params: { id: item.id } })
        },
        /**
         * Check if user is from the same country as the tenant
         */
        isCountry(country) {
            const reseller = this.user?.reseller

            if (reseller) {
                return reseller.country === country
            }
            return true
        },
        /**
         * Check if users reseller is Goran or Ivan
         */
        isGoranOrIvanReseller() {
            //If user dont have reseller return false
            if (!this.user.reseller) return false
            //By their reseller hash we check if user is Goran or Ivan
            return this.user.reseller.hash === 'lm360rh852' || this.user.reseller.hash === 'oa146vo061'
        },
    }
};
</script>

<style
    lang="scss"
    scoped
>
.tenants {
    padding: 20px;
    background: white;
    border-radius: 4px;
    border: 1px solid #d3d5dc;

    .search {
        max-width: 400px;
        margin-bottom: 16px;
    }
}

.w-100 {
    width: 100%;
}
</style>
