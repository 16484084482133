<template>
  <v-form v-if="form">
    <v-text-field
      v-model="form.name"
      :error-messages="form.$errors.name"
      :label="$t('general.name')"
      @blur="form.validate('name')"
    />

    <v-text-field
      v-model="form.email"
      :error-messages="form.$errors.email"
      :label="$t('general.email')"
      @blur="form.validate('email')"
    />

    <v-select
      v-model="form.role"
      :items="['admin', 'main_reseller', 'reseller']"
      :error-messages="form.$errors.role"
      :label="$t('general.role')"
      @blur="form.validate('role')"
    >
    </v-select>

    <v-select
      v-model="form.reseller_id"
      :items="resellers"
      :error-messages="form.$errors.reseller_id"
      :label="$t('general.reseller')"
      item-text="name"
      item-value="id"
      @blur="form.validate('reseller_id')"
    />

    <v-text-field
      v-model="form.password"
      :error-messages="form.$errors.password"
      :label="$t('general.password')"
      :append-icon="showPassword ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline'"
      :type="showPassword ? 'text' : 'password'"
      autocomplete="new-password"
      @click:append="showPassword = !showPassword"
      @blur="form.validate('password')"
    />

    <v-text-field
      v-model="form.password_confirmation"
      :error-messages="form.$errors.passwordConfirmation"
      :label="$t('general.confirm_password')"
      :append-icon="showPasswordConfirmation ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline'"
      :type="showPasswordConfirmation ? 'text' : 'password'"
      @click:append="showPasswordConfirmation = !showPasswordConfirmation"
      @blur="form.validate('passwordConfirmation')"
    />

    <div class="d-flex justify-center mb-3">
      <v-btn
        color="primary"
        outlined
        @click="save"
      >
        {{ $t('general.save') }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import ApiService from '@/services/ApiService'
import * as rules from 'vuelidate/lib/validators'

export default {
  props: {
    tenant: {
      type: Object,
      default: () => null
    }
  },

  created() {
    this.form = this.createForm(this.tenant)
  },

  data() {
    return {
      form: null,
      showPassword: false,
      showPasswordConfirmation: false,
      showPin: false,
      showPinConfirmation: false,
      resellers: []
    };
  },

  validations: {
    form: {
      name: { required: rules.required },
      email: { required: rules.required, email: rules.email },
      reseller_id: { required: rules.required },
      password: { minLength: rules.minLength(8) },
      passwordConfirmation: {
        sameAs: rules.sameAs(function () { return this.password })
      },
      role: { required: rules.required }

    }
  },

  computed: {
    isFiscal() {
      return locale === 'me'
    },
  },

  methods: {
    createForm(tenant = null) {
      //Populate resellers array
      this.getResellers();

      return new CodForm(this, 'form', {
        id: tenant ? tenant.id : null,
        name: tenant ? tenant.name : null,
        email: tenant ? tenant.email : null,
        password: null,
        password_confirmation: null,
        reseller_id: tenant ? tenant.reseller_id : null,
        role: tenant ? tenant.role : null,
      })

    },
    async getResellers() {
      const response = await ApiService.get("/admin/resellers");
      this.resellers = response.data.resellers
    },
    /**
     * Save Venue
     */
    save() {
      if (!this.form.validate()) return

      ApiService.patch(`/admin/users/${this.form.id}/update`, this.form).then(response => {
        this.$emit('save', response.data.user)
        this.$snackbar('User updated', 'success');
      });
    }
  }
};
</script>

<style
  lang="scss"
  scoped
>
.date-picker {
  margin: 0 auto;
}
</style>
