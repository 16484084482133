import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

Vue.use(Vuex);

const state = {
    user: null,
    appConfig: null,
    locales: [
        { value: "me", text: "Montenegro" },
        { value: "rs", text: "Serbia" },
        { value: "ba", text: "Bosnia and Herzegovina" },
        { value: "en", text: "USA" }
    ],
    companyDetails: {
        email: "",
        phone: ""
    },
    resellerDetails: {},
    taxRates: []
};

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters
});
