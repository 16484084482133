import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.value,"width":"500"},on:{"input":function($event){return _vm.$emit('input', false)}}},[_c(VCard,[_c(VCardTitle,{staticClass:"dialog-header"},[_vm._v("\n      "+_vm._s(_vm.$t('general.clear_stocks'))+"\n    ")]),_vm._v(" "),_c(VCardText,{staticClass:"text-center flex-column mt-4"},[_c('div',{staticClass:"text-h6 error--text"},[_c('div',{staticClass:"mb-4"},[_vm._v("\n          "+_vm._s(_vm.$t('general.clear_stocks_description'))+"\n        ")]),_vm._v(" "),_c('div',[_vm._v("\n          "+_vm._s(_vm.$t('general.clear_stocks_warning'))+"\n        ")])])]),_vm._v(" "),_c(VCardActions,{staticClass:"d-flex justify-center pb-4"},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v("\n        "+_vm._s(_vm.$t('general.cancelBtn'))+"\n      ")]),_vm._v(" "),_c(VBtn,{staticClass:"error--text",attrs:{"outlined":"","depressed":""},on:{"click":_vm.clearStocks}},[_vm._v("\n        "+_vm._s(_vm.$t('general.confirm'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }