<template>
  <v-dialog
    v-if="value"
    :value="value"
    width="500"
    @input="$emit('input', false)"
  >
    <v-card>
      <v-card-title class="dialog-header">
        {{ $t('general.phoneNumber') }}
      </v-card-title>

      <v-card-text class="overflow-auto">
        <h3>{{ curTenant.phone }} </h3>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: false
    },
    curTenant: {
      type: Object,
      default: () => { },
    },
  }
}
</script>
