import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tenants"},[_c(VRow,[_c(VTextField,{staticClass:"search",attrs:{"label":_vm.$t('customers.search'),"append-icon":"far fa-search","clearable":"","clear-icon":"mdi mdi-magnify","single-line":"","hide-details":""},on:{"input":function($event){return _vm.filterUsers()}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_vm._v(" "),_c(VCol,{attrs:{"align":"right"}},[_c(VBtn,{attrs:{"outlined":""},on:{"click":function($event){_vm.show.createUser = true;}}},[_c(VIcon,[_vm._v("mdi mdi-plus")]),_vm._v("\n        "+_vm._s(_vm.$t('general.create'))+"\n      ")],1)],1)],1),_vm._v(" "),_c(VDataTable,{staticClass:"operations striped",attrs:{"headers":_vm.headers,"items":_vm.users,"options":_vm.pagination,"server-items-length":_vm.total,"footer-props":{ 'items-per-page-options': [50, 100, 200, 500] },"items-per-page":_vm.pagination.itemsPerPage,"loading":_vm.loading,"mobile-breakpoint":0},on:{"update:options":function($event){_vm.pagination=$event},"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',[_vm._v(_vm._s(index + 1))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.reseller ? item.reseller.name : ''))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.email))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.role))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm._f("dateFromUtc")(item.created_at)))]),_vm._v(" "),_c('td',{staticClass:"w60",attrs:{"align":"center"}},[_c(VMenu,{attrs:{"bottom":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi mdi-dots-horizontal")])],1)]}}],null,true)},[_vm._v(" "),_c(VList,[_c(VListItem,{key:1,on:{"click":function($event){return _vm.editUser(item)}}},[_c(VListItemAction,{staticClass:"mr-4 flex justify-content-center"},[_c(VIcon,[_vm._v("mdi mdi-account-edit")])],1),_vm._v(" "),_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('general.edit_tenant')))])],1)],1)],1)],1)])]}}])}),_vm._v(" "),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.show.editUser),callback:function ($$v) {_vm.$set(_vm.show, "editUser", $$v)},expression:"show.editUser"}},[(_vm.curUser)?_c('div',{staticClass:"edit-venue"},[_c('div',{staticClass:"edit-venue-header"},[_vm._v("\n        "+_vm._s(_vm.$t('general.edit_tenant'))+"\n      ")]),_vm._v(" "),_c('user-form',{staticClass:"edit-venue-content",attrs:{"tenant":_vm.curUser},on:{"save":_vm.onTenantUpdate}})],1):_vm._e()]),_vm._v(" "),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.show.createUser),callback:function ($$v) {_vm.$set(_vm.show, "createUser", $$v)},expression:"show.createUser"}},[_c('div',{staticClass:"edit-venue"},[_c('div',{staticClass:"edit-venue-header"},[_vm._v("\n        "+_vm._s(_vm.$t('general.create'))+"\n      ")]),_vm._v(" "),_c('user-create-form',{staticClass:"edit-venue-content",on:{"save":_vm.onTenantUpdate}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }