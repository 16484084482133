import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.value,"width":"500"},on:{"input":function($event){return _vm.$emit('input', false)}}},[(_vm.curTenant)?_c(VCard,{staticClass:"edit-venue"},[_c(VCardTitle,{staticClass:"dialog-header"},[_vm._v("\n      "+_vm._s(_vm.$t('general.delete_products'))+"\n    ")]),_vm._v(" "),_c(VCardText,{staticClass:"d-flex justify-center text-center mt-4"},[_c('span',{staticClass:"error--text text-h5"},[_vm._v(_vm._s(_vm.$t('general.delete_products_message', { tenant: _vm.curTenant.email })))])]),_vm._v(" "),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v("\n        "+_vm._s(_vm.$t('general.cancelBtn'))+"\n      ")]),_vm._v(" "),_c(VBtn,{staticClass:"error--text",attrs:{"outlined":""},on:{"click":_vm.deleteAllProducts}},[_vm._v("\n        "+_vm._s(_vm.$t('general.confirm'))+"\n      ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }