import * as mutationTypes from "@/store/mutationTypes";
import UserService from "@/services/UserService";

export default {
    /**
     * Initialize application
     */
    async initializeApp({ commit, dispatch, getters }) {
        // get the initial data from server and set the store
        const response = await ApiService.get("/initialize");

        // Set User
        UserService.setUser(response.data.user);

        // Update App Config in the Store
        commit(mutationTypes.SET_APP_CONFIG, {
            appConfig: response.data.appConfig
        });

        // Update Tax Rates in the Store
        commit(mutationTypes.SET_TAX_RATES, {
            taxRates: response.data.taxRates
        });
    }
};
