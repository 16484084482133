import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"unfiscalized-bills"},[_c(VRow,{staticClass:"mb-4"},[_c(VCol,{attrs:{"cols":"12","sm":"5"}},[_c(VTextField,{attrs:{"label":_vm.$t('tenant.search'),"append-icon":"far fa-search","clearable":"","clear-icon":"mdi mdi-magnify","single-line":"","hide-details":""},on:{"input":function($event){return _vm.filterUnfiscalizedBills()}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_vm._v(" "),_c(VCol,{staticClass:"d-flex align-end justify-end",attrs:{"cols":"2","sm":"1"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.getUnfiscalizedBills()}}},[_c(VIcon,[_vm._v("mdi mdi-refresh")])],1)],1)],1),_vm._v(" "),_c(VDataTable,{staticClass:"operations striped",attrs:{"headers":_vm.headers,"items":_vm.unfiscallizedBills,"options":_vm.pagination,"server-items-length":_vm.total,"footer-props":{'items-per-page-options': [20, 50, 100, 200]},"items-per-page":_vm.pagination.itemsPerPage,"loading":_vm.loading,"mobile-breakpoint":0},on:{"update:options":function($event){_vm.pagination=$event},"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.tenant.name))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.tenant.email))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.number))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.hash))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm._f("dateFromUtc")(item.last_activity_at)))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }