import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modules"},[_c(VRow,{staticClass:"mb-4"},[_c(VCol,{attrs:{"cols":"6","sm":"6"}},[_c(VTextField,{attrs:{"label":_vm.$t('general.search'),"append-icon":"far fa-search","clearable":"","clear-icon":"mdi mdi-magnify","single-line":"","hide-details":""},on:{"input":function($event){return _vm.filterModules()}},model:{value:(_vm.nameFilter),callback:function ($$v) {_vm.nameFilter=$$v},expression:"nameFilter"}})],1),_vm._v(" "),_c(VCol,{staticClass:"mt-3",attrs:{"align":"right"}},[(_vm.$store.state.user.role === 'admin')?_c(VBtn,{attrs:{"outlined":""},on:{"click":function($event){_vm.show.createModule = true; _vm.createIncrement++}}},[_c(VIcon,[_vm._v("mdi mdi-plus")]),_vm._v("\n                "+_vm._s(_vm.$t('general.create'))+"\n            ")],1):_vm._e()],1)],1),_vm._v(" "),_c(VDataTable,{staticClass:"operations striped",attrs:{"headers":_vm.headers,"items":_vm.modules,"options":_vm.pagination,"server-items-length":_vm.total,"footer-props":{'items-per-page-options': [20, 50, 100, 200, 500, 1000]},"items-per-page":_vm.pagination.itemsPerPage,"loading":_vm.loading,"mobile-breakpoint":0},on:{"update:options":function($event){_vm.pagination=$event},"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t(("modules." + (item.name)))))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.status))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.description))]),_vm._v(" "),(_vm.$store.state.user.role === 'admin')?_c('td',{staticClass:"w60",attrs:{"align":"center"}},[_c(VMenu,{attrs:{"bottom":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi mdi-dots-horizontal")])],1)]}}],null,true)},[_vm._v(" "),(_vm.$store.state.user.role === 'admin')?_c(VList,[[_c(VListItem,{key:2,on:{"click":function($event){return _vm.openEditModule(item)}}},[_c(VListItemAction,{staticClass:"mr-4 flex justify-content-center"},[_c(VIcon,[_vm._v("mdi mdi-account-edit")])],1),_vm._v(" "),_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('general.edit')))])],1)]],2):_vm._e()],1)],1):_vm._e()])]}}])}),_vm._v(" "),_c(VDialog,{key:_vm.createIncrement,attrs:{"width":"500"},model:{value:(_vm.show.createModule),callback:function ($$v) {_vm.$set(_vm.show, "createModule", $$v)},expression:"show.createModule"}},[_c('div',{staticClass:"dialog"},[_c('div',{staticClass:"dialog-header"},[_vm._v("\n                "+_vm._s(_vm.$t('modules.create'))+"\n            ")]),_vm._v(" "),_c('module-form',{staticClass:"dialog-content",attrs:{"action":'post'},on:{"save":_vm.onModuleUpdate}})],1)]),_vm._v(" "),_c(VDialog,{key:_vm.editIncrement,attrs:{"width":"500"},model:{value:(_vm.show.editModule),callback:function ($$v) {_vm.$set(_vm.show, "editModule", $$v)},expression:"show.editModule"}},[_c('div',{staticClass:"dialog"},[_c('div',{staticClass:"dialog-header"},[_vm._v("\n                "+_vm._s(_vm.$t('modules.edit'))+"\n            ")]),_vm._v(" "),_c('module-form',{staticClass:"dialog-content",attrs:{"module":_vm.curModule,"action":'patch'},on:{"save":_vm.onModuleUpdate}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }