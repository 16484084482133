<template>
  <v-dialog
    :value="value"
    width="500"
    @input="$emit('input', false)"
  >
    <v-card
      v-if="curTenant"
      class="edit-venue"
    >
      <v-card-title class="dialog-header">
        {{ $t('general.delete_products') }}
      </v-card-title>

      <v-card-text class="d-flex justify-center text-center mt-4">
        <span class="error--text text-h5">{{ $t('general.delete_products_message', { tenant: curTenant.email })
          }}</span>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          text
          @click="$emit('input', false)"
        >
          {{ $t('general.cancelBtn') }}
        </v-btn>

        <v-btn
          outlined
          class="error--text"
          @click="deleteAllProducts"
        >
          {{ $t('general.confirm') }}
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    curTenant: {
      type: Object,
      default: () => null,
    },
  },

  methods: {
    async deleteAllProducts() {
      await ApiService.delete(`/admin/tenants/${this.curTenant.id}/products`).then(() => {
        this.$snackbar('Products deleted successfully', 'success');
        this.$emit('input', false);
        this.$emit('success');
      }).catch((error) => {
        this.$snackbar('Failed to delete products', 'error');
      });
    },
  },
}
</script>
