import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import ClickOutside from 'vuetify/lib/directives/click-outside';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{def: ClickOutside,name:"click-outside",rawName:"v-click-outside",value:(_vm.resetDialog),expression:"resetDialog"}]},[_c('div',{staticClass:"dialog-header"},[(_vm.selectedOption === 'addContract')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('general.add_contracts'))+" - "),_c('b',[_vm._v(_vm._s(_vm.selectedItem.email)+" ")])]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('general.change_package'))+" - "),_c('b',[_vm._v(_vm._s(_vm.selectedItem.tenant.email)+" ")])])]),_vm._v(" "),_c(VList,{staticClass:"justify-center"},[_c(VTextField,{staticClass:"ma-3",attrs:{"label":_vm.$t('general.search')},on:{"input":_vm.search},model:{value:(_vm.nameFilter),callback:function ($$v) {_vm.nameFilter=$$v},expression:"nameFilter"}}),_vm._v(" "),_vm._l((_vm.contracts),function(contract,index){return _c('div',{key:contract.id,attrs:{"mb-4":""}},[_c(VListItem,[_c(VListItemAction,[(_vm.isAvailable(contract.app))?_c(VCheckbox,{on:{"change":function($event){return _vm.changeSelectedApp($event, contract.app, index)}},model:{value:(contract.checked),callback:function ($$v) {_vm.$set(contract, "checked", $$v)},expression:"contract.checked"}}):_vm._e()],1),_vm._v(" "),_c(VRow,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(contract.name))]),_vm._v(" "),_c(VListItemSubtitle,[_vm._v(_vm._s(contract.price)+" €")]),_vm._v(" "),_c(VListItemSubtitle,[_vm._v(_vm._s(contract.months_duration)+" months")]),_vm._v(" "),_c(VListItemSubtitle)],1),_vm._v(" "),(contract.checked)?_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({staticClass:"shrink",attrs:{"label":"Datum ugovora","prepend-icon":"mdi-calendar","readonly":""},model:{value:(contract.date),callback:function ($$v) {_vm.$set(contract, "date", $$v)},expression:"contract.date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu2[index]),callback:function ($$v) {_vm.$set(_vm.menu2, index, $$v)},expression:"menu2[index]"}},[_vm._v(" "),_c(VDatePicker,{attrs:{"type":_vm.$i18n.locale === 'sr' ? 'month' : 'date'},on:{"input":function($event){_vm.menu2[index] = false}},model:{value:(contract.date),callback:function ($$v) {_vm.$set(contract, "date", $$v)},expression:"contract.date"}})],1):_vm._e(),_vm._v(" "),_c(VListItemAction,{staticStyle:{"max-width":"20%"}},[(contract.checked)?_c(VTextField,{staticStyle:{"height":"35px"},attrs:{"flat":"","solo":"","type":"number","label":"Number","append-outer-icon":"add","prepend-icon":"remove"},on:{"click:append-outer":function($event){contract.quantity = contract.quantity + 1},"click:prepend":function($event){contract.quantity = contract.quantity - 1}},model:{value:(contract.quantity),callback:function ($$v) {_vm.$set(contract, "quantity", $$v)},expression:"contract.quantity"}}):_vm._e()],1)],1)],1),_vm._v(" "),_c(VDivider)],1)}),_vm._v(" "),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{attrs:{"outlined":"","color":"blue"},on:{"click":function($event){return _vm.saveContracts(_vm.selectedItem.id)}}},[_vm._v(_vm._s(_vm.$t('general.save')))])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }