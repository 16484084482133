<template>
    <v-container>
        <div class="content">
            <v-progress-circular
                v-if="!loadingContainer"
                indeterminate
            />
        </div>

        <template v-if="loadingContainer">
            <v-card
                class="mb-4"
                flat
            >
                <v-card-title style="text-align: left">{{ companyDetails.name }}</v-card-title>

                <v-card-text>
                    <v-text-field
                        v-model="companyDetails.tax_id_number"
                        :label="$t('general.tax_id_number')"
                        disabled
                    />

                    <v-text-field
                        v-model="editEmail"
                        :label="$t('general.email')"
                        :append-icon="'mdi mdi-content-save'"
                        @click:append="updateCompanyInfo('email')"
                        :disabled="!canEdit"
                    />

                    <v-text-field
                        v-model="editPhone"
                        :label="$t('general.phone')"
                        :append-icon="'mdi mdi-content-save'"
                        @click:append="updateCompanyInfo('phone')"
                        :disabled="!canEdit"
                    />
                </v-card-text>

                <v-card-actions>
                    <span
                        class="text-h3"
                        :style="[companyDetails.amount_due <= 0 ? { 'color': 'green' } : { 'color': 'red' }]"
                    >
                        {{ companyDetails.amount_due * (-1) | twoDecimals }}€
                    </span>
                </v-card-actions>
            </v-card>

            <v-expansion-panels
                class="mb-4"
                flat
                :value="0"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        {{ $t('admin_navigation.customers') }}
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="filter"
                                    :label="$t('general.search_tenants')"
                                    append-icon="far fa-search"
                                    clearable
                                    clear-icon="mdi mdi-magnify"
                                    single-line
                                    hide-details
                                    @input="searchTenants()"
                                    cols="6"
                                />
                            </v-col>
                        </v-row>

                        <v-data-table
                            class="operations striped"
                            :headers="tenantHeaders"
                            :items="tempTenants"
                            :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
                            :mobile-breakpoint="0"
                        >
                            <template v-slot:item="{ contractHeaders, item }">
                                <tr>
                                    <td>{{ item.email }}</td>
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.phone }}</td>
                                    <td>
                                        <span
                                            v-if="item.status === 'active'"
                                            style="color:green"
                                        >{{ item.status | upperCase }}</span>
                                        <span
                                            v-else
                                            style="color:red"
                                        >{{ item.status | upperCase }}</span>
                                    </td>
                                    <td
                                        class="w60"
                                        align="center"
                                    >
                                        <v-menu
                                            bottom
                                            transition="slide-y-transition"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    icon
                                                >
                                                    <v-icon>mdi mdi-dots-horizontal</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <template>
                                                    <v-list-item
                                                        v-if="isAdmin || isMainReseller || isGoranOrIvanReseller"
                                                        :key="1"
                                                        @click="showContracts(item, 'addContract')"
                                                    >
                                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                                            <v-icon>mdi mdi-plus</v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-title>{{ $t('general.add_contracts')
                                                            }}</v-list-item-title>
                                                    </v-list-item>
                                                    <v-divider />
                                                </template>
                                            </v-list>
                                            <v-list>
                                                <template>
                                                    <v-list-item
                                                        :key="2"
                                                        @click="showActivationDialog(item)"
                                                    >
                                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                                            <v-icon v-if="item.status === 'active'">mdi
                                                                mdi-minus-circle</v-icon>
                                                            <v-icon v-else>mdi mdi-plus-circle</v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-title v-if="item.status === 'active'">{{
                                                            $t('general.deactivate') }}</v-list-item-title>
                                                        <v-list-item-title v-else>{{ $t('general.activate')
                                                            }}</v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                            </v-list>
                                        </v-menu>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <v-expansion-panels
                v-if="showContractsExpansionPanel"
                class="mb-4"
                flat
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        {{ $t('general.contracts') }}
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <v-data-table
                            class="operations striped"
                            :headers="contractHeaders"
                            :items="tenantContracts"
                            :options.sync="pagination"
                            :server-items-length="total"
                            :items-per-page.sync="pagination.itemsPerPage"
                            :footer-props="{ 'items-per-page-options': [20, 50, 100, 200] }"
                            :mobile-breakpoint="0"
                            :loading="loading"
                        >
                            <template v-slot:item="{ tenantHeaders, item }">
                                <tr>
                                    <td>{{ item.tenant.email }}</td>
                                    <td>{{ item.contract.name }} x {{ item.quantity }} </td>
                                    <td>{{ item.price }}</td>
                                    <td>{{ item.discount }}</td>
                                    <td>{{ item.num_of_allowed_modules }}</td>
                                    <td>{{ item.active_modules.length }}</td>
                                    <td>{{ item.started_at | dateFromUtc }}</td>
                                    <td>{{ item.expires_at | dateFromUtc }}</td>
                                    <td>
                                        <span
                                            v-if="item.terminated_at"
                                            style="color:red"
                                        >
                                            {{ item.terminated_at | dateFromUtc }}
                                        </span>
                                        <span
                                            v-else
                                            style="color: green"
                                        >
                                            ACTIVE
                                        </span>
                                    </td>
                                    <td>

                                        <v-autocomplete
                                            v-model="item.reseller"
                                            :label="$t('companies.search_reseller')"
                                            :items="resellers"
                                            item-text="state"
                                            item-value="abbr"
                                            label=""
                                            persistent-hint
                                            return-object
                                            single-line
                                            @input="openChangeResellerDialog($event, item.id)"
                                            @click="tempReseller = item.reseller"
                                        >
                                        </v-autocomplete>
                                    </td>

                                    <td
                                        class="w60"
                                        align="center"
                                    >
                                        <v-menu
                                            bottom
                                            transition="slide-y-transition"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    icon
                                                >
                                                    <v-icon>mdi mdi-dots-horizontal</v-icon>
                                                </v-btn>
                                            </template>


                                            <v-list v-if="isAdmin || isMainReseller || isGoranOrIvanReseller">
                                                <template v-if="!item.terminated_at && (isAdmin || isMainReseller)">
                                                    <v-list-item
                                                        :key="1"
                                                        @click="showModuleAddDialog(item)"
                                                    >
                                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                                            <v-icon>mdi mdi-package-variant</v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-title>{{ $t('modules.modules')
                                                            }}</v-list-item-title>
                                                    </v-list-item>

                                                    <v-divider />
                                                </template>

                                                <template v-if="isAdmin">
                                                    <v-list-item
                                                        :key="2"
                                                        @click="showModuleHistoryDialog(item)"
                                                    >
                                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                                            <v-icon>mdi mdi-package-down</v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-title>{{ $t('modules.moduleHistory')
                                                            }}</v-list-item-title>
                                                    </v-list-item>

                                                    <v-divider />
                                                </template>

                                                <template v-if="!item.terminated_at && (isAdmin || isMainReseller)">
                                                    <v-list-item
                                                        :key="3"
                                                        @click="showIntegrationsAddDialog(item)"
                                                    >
                                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                                            <v-icon>mdi mdi-buffer</v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-title>{{ $t('integrations.integrations')
                                                            }}</v-list-item-title>
                                                    </v-list-item>

                                                    <v-divider />
                                                </template>

                                                <template v-if="isAdmin">
                                                    <v-list-item
                                                        :key="4"
                                                        @click="showIntegrationHistoryDialog(item)"
                                                    >
                                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                                            <v-icon>mdi mdi-inbox-arrow-down</v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-title>{{
                                                            $t('integrations.integrationHistory')
                                                            }}</v-list-item-title>
                                                    </v-list-item>

                                                    <v-divider />
                                                </template>

                                                <template
                                                    v-if="item.terminated_at && (isAdmin || isMainReseller || isGoranOrIvanReseller)"
                                                >
                                                    <v-list-item
                                                        :key="5"
                                                        @click="showContractActivationDialog(item.id)"
                                                    >
                                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                                            <v-icon>mdi mdi-file-document-edit</v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-title>{{ $t('general.activate')
                                                            }}</v-list-item-title>
                                                    </v-list-item>
                                                </template>

                                                <template v-else>
                                                    <v-list-item
                                                        :key="6"
                                                        @click="showContracts(item, 'updateContract')"
                                                    >
                                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                                            <v-icon>mdi mdi-monitor-edit</v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-title>{{ $t('general.change_package')
                                                            }}</v-list-item-title>
                                                    </v-list-item>

                                                    <v-divider />

                                                    <v-list-item
                                                        :key="7"
                                                        @click="showTerminateDialog(item.id)"
                                                    >
                                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                                            <v-icon>mdi mdi-minus</v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-title>{{ $t('general.terminate')
                                                            }}</v-list-item-title>
                                                    </v-list-item>
                                                </template>

                                                <template v-if="isAdmin">
                                                    <v-divider />

                                                    <v-list-item
                                                        :key="8"
                                                        @click="showTenantContractLogs(item.id)"
                                                    >
                                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                                            <v-icon>mdi mdi-information</v-icon>
                                                        </v-list-item-action>

                                                        <v-list-item-title>
                                                            {{ $t('general.logs') }}
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                            </v-list>
                                        </v-menu>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <v-expansion-panels
                v-if="isAdmin || isMainReseller"
                flat
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        {{ $t('general.invoices_and_payments') }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col cols="5">
                                <v-btn
                                    style="display:inline"
                                    outlined
                                    @click="showCreateInvoiceDialog()"
                                >
                                    {{ $t('companies.payments.create_invoice') }}
                                </v-btn>
                            </v-col>
                            <v-col>
                                <h2 style="display:inline;">
                                    <span
                                        :style="[companyDetails.amount_due <= 0 ? { 'color': 'green' } : { 'color': 'red' }]"
                                    >
                                        {{ companyDetails.amount_due * (-1) | twoDecimals }}€
                                    </span>
                                </h2>
                            </v-col>

                            <v-col align="right">
                                <v-btn
                                    outlined
                                    @click="show.paymentDialog = true"
                                >
                                    {{ $t('companies.payments.last_invoice') }}
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <h4
                                    style="text-align: left"
                                    class="text-overline"
                                >
                                    Invoices
                                </h4>

                                <v-data-table
                                    class="operations striped"
                                    :headers="invoiceHeaders"
                                    :items="invoices"
                                    :options.sync="invoicePagination"
                                    :server-items-length="invoiceTotal"
                                    :items-per-page.sync="invoicePagination.itemsPerPage"
                                    :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
                                    :mobile-breakpoint="0"
                                    :loading="invoiceLoading"
                                >
                                    <template v-slot:item="{ invoiceHeaders, item }">
                                        <tr :style="item.cancels_id || item.canceled_by ? 'color:red' : 'color:black'">
                                            <td>{{ item.billing_start_date | dateFromUtc | onlyDate }}</td>
                                            <td>{{ item.billing_end_date | dateFromUtc | onlyDate }}</td>
                                            <td>{{ item.amount_with_taxes | twoDecimals }}</td>
                                            <td> <a
                                                    v-if="item.url"
                                                    :href="item.url"
                                                    target="_blank"
                                                >Invoice</a>
                                                <span v-else>-</span>
                                            </td>
                                            <td>
                                                <v-menu
                                                    bottom
                                                    transition="slide-y-transition"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            v-on="on"
                                                            icon
                                                        >
                                                            <v-icon>mdi mdi-dots-horizontal</v-icon>
                                                        </v-btn>
                                                    </template>

                                                    <v-list>
                                                        <template>
                                                            <v-list-item
                                                                :key="1"
                                                                @click="resendInvoice(item.id, 'invoice')"
                                                            >
                                                                <v-list-item-action
                                                                    class="mr-4 flex justify-content-center"
                                                                >
                                                                    <v-icon>mdi mdi-email</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-title>
                                                                    {{ $t('general.resend') }}
                                                                </v-list-item-title>
                                                                <v-divider />
                                                            </v-list-item>
                                                            <v-divider />
                                                        </template>
                                                    </v-list>
                                                    <v-list v-if="!item.cancels_id && !item.canceled_by">
                                                        <template>
                                                            <v-list-item
                                                                :key="2"
                                                                @click="openCancelInvoiceDialog(item)"
                                                            >
                                                                <v-list-item-action
                                                                    class="mr-4 flex justify-content-center"
                                                                >
                                                                    <v-icon>mdi mdi-minus-box</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-title>Cancel</v-list-item-title>
                                                                <v-divider />
                                                            </v-list-item>
                                                            <v-divider />
                                                        </template>
                                                    </v-list>

                                                    <v-list v-if="isAdmin">
                                                        <template>
                                                            <v-list-item
                                                                :key="3"
                                                                @click="showInvoiceLogs(item.id)"
                                                            >
                                                                <v-list-item-action
                                                                    class="mr-4 flex justify-content-center"
                                                                >
                                                                    <v-icon>mdi mdi-information</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-title>
                                                                    {{ $t('general.logs') }}
                                                                </v-list-item-title>
                                                                <v-divider />
                                                            </v-list-item>
                                                            <v-divider />
                                                        </template>
                                                    </v-list>

                                                </v-menu>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-col>

                            <div
                                v-if="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl"
                                class="vl"
                            />

                            <v-col>
                                <h4
                                    style="text-align: left"
                                    class="text-overline"
                                >
                                    Payments
                                </h4>

                                <v-data-table
                                    class="operations striped"
                                    :headers="paymentHeaders"
                                    :items="payments"
                                    :options.sync="paymentPagination"
                                    :server-items-length="paymentTotal"
                                    :items-per-page.sync="paymentPagination.itemsPerPage"
                                    :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
                                    :mobile-breakpoint="0"
                                    :loading="paymentLoading"
                                >
                                    <template v-slot:item="{ paymentHeaders, item }">
                                        <tr :style="item.cancels_id || item.canceled_by ? 'color:red' : 'color:black'">
                                            <td>{{ item.amount | twoDecimals }}</td>
                                            <td>{{ item.paid_at | dateFromUtc | onlyDate }}</td>
                                            <td>{{ item.reference_number }}</td>
                                            <td>{{ item.created_at | dateFromUtc }}</td>
                                            <td>
                                                <v-menu
                                                    bottom
                                                    transition="slide-y-transition"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            v-on="on"
                                                            icon
                                                        >
                                                            <v-icon>mdi mdi-dots-horizontal</v-icon>
                                                        </v-btn>
                                                    </template>

                                                    <v-list v-if="!item.cancels_id && !item.canceled_by">
                                                        <template>
                                                            <v-list-item
                                                                :key="3"
                                                                @click="openUpdatePaymentDialog(item)"
                                                            >
                                                                <v-list-item-action
                                                                    class="mr-4 flex justify-content-center"
                                                                >
                                                                    <v-icon>mdi mdi-update</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-title>Update</v-list-item-title>
                                                                <v-divider />
                                                            </v-list-item>
                                                            <v-divider />
                                                        </template>
                                                    </v-list>
                                                    <v-list v-if="!item.cancels_id && !item.canceled_by">
                                                        <template>
                                                            <v-list-item
                                                                :key="2"
                                                                @click="openCancelPaymentDialog(item)"
                                                            >
                                                                <v-list-item-action
                                                                    class="mr-4 flex justify-content-center"
                                                                >
                                                                    <v-icon>mdi mdi-minus-box</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-title>Cancel</v-list-item-title>
                                                                <v-divider />
                                                            </v-list-item>
                                                            <v-divider />
                                                        </template>
                                                    </v-list>

                                                    <v-list>
                                                        <template
                                                            v-if="!item.cancels_id && !item.canceled_by && isAdmin"
                                                        >
                                                            <v-list-item
                                                                :key="1"
                                                                @click="openDeletePaymentDialog(item)"
                                                            >
                                                                <v-list-item-action
                                                                    class="mr-4 flex justify-content-center"
                                                                >
                                                                    <v-icon>mdi mdi-delete</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-title>
                                                                    {{ $t('general.delete') }}
                                                                </v-list-item-title>
                                                                <v-divider />
                                                            </v-list-item>

                                                            <v-divider />
                                                        </template>
                                                    </v-list>

                                                    <v-list v-if="isAdmin">
                                                        <template>
                                                            <v-list-item
                                                                :key="1"
                                                                @click="showPaymentLogs(item.id)"
                                                            >
                                                                <v-list-item-action
                                                                    class="mr-4 flex justify-content-center"
                                                                >
                                                                    <v-icon>mdi mdi-information</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-title>
                                                                    {{ $t('general.logs') }}
                                                                </v-list-item-title>
                                                            </v-list-item>
                                                        </template>
                                                    </v-list>
                                                </v-menu>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <v-dialog
                v-model="show.contracts"
                width="650"
            >
                <ContractForm
                    :contracts="contracts"
                    :selectedItem="selectedItem"
                    :selectedOption="selectedOption"
                    @closeDialog="closeContractFormDialog($event)"
                    @search="searchContracts($event)"
                    @closeDialogChangePackage="closeDialogChangePackage($event)"
                />
            </v-dialog>

            <v-dialog
                v-model="show.terminateDialog"
                width="400"
                :key="Math.random()"
            >
                <TerminateContract
                    :selectedContract="selectedContract"
                    @terminateContract="terminateContract($event)"
                />
            </v-dialog>

            <!-- Activating contract dialog -->
            <v-dialog
                v-model="show.activateContractDialog"
                width="400"
                :key="Math.random()"
            >
                <v-card>
                    <div class="dialog-header">
                        {{ $t('contracts.activateHeading') }}
                    </div>
                    <v-card-text class="mt-3 text-subtitle-1">
                        {{ $t('contracts.activateMessage') }}

                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            outlined
                            color="red"
                            @click="show.activateContractDialog = false"
                        >
                            {{ $t('general.cancelBtn') }}

                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            outlined
                            @click="activateContract"
                        >
                            {{ $t('general.activate') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="show.createInvoiceDialog"
                width="400"
            >
                <v-card width="400">
                    <v-card-title class="dialog-header">
                        {{ $t('companies.payments.create_invoice') }}
                    </v-card-title>

                    <v-card-text class="d-flex justify-center">
                        <v-date-picker
                            v-model="createInvoiceDate"
                            class="rounded-0"
                            label="Date"
                            range
                        />
                    </v-card-text>

                    <v-card-actions class="justify-center">
                        <v-btn
                            outlined
                            @click="createInvoice()"
                            :loading="creatingInvoiceClicked"
                        >
                            {{ $t('general.create') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="show.confirmDialog"
                width="250"
                :key="Math.random()"
            >
                <div class="dialog-header">
                    {{ $t('companies.details.change_reseller') }}
                </div>
                <div>
                    <v-card>
                        <h3 style="text-align:center"> {{ reseller.name }}? </h3>
                        <v-card-actions class="justify-center">
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="changeReseller()"
                            >
                                OK
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="resetSelectReseller"
                            >
                                Cancel
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                </div>
            </v-dialog>

            <v-dialog
                v-model="show.paymentDialog"
                width="350"
            >
                <v-card>
                    <div class="dialog-header">
                        {{ $t('companies.payments.dialog') }}
                    </div>
                    <div style="padding:4%">
                        <div style="text-align:center">
                            <v-date-picker
                                v-model="payment.date"
                                label="Date"
                            >
                            </v-date-picker>
                        </div>
                        <v-text-field
                            v-model="payment.amount_with_taxes"
                            :label="$t('general.amount')"
                        ></v-text-field>
                        <v-text-field
                            v-model="payment.referenceNumber"
                            :label="$t('companies.payments.reference_number')"
                        ></v-text-field>
                        <div style="text-align:center">
                            <v-btn
                                outlined
                                @click="completePayment()"
                            >{{ $t('general.pay') }}</v-btn>
                        </div>
                    </div>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="show.confirmActivationDialog"
                width="470"
            >
                <v-card>
                    <div class="dialog-header">
                        Deactivation
                    </div>
                    <p style="padding:2%;text-align:center">
                        Are you sure that you want to <b>{{ selectedTenantForActivation.status === 'active' ?
                            'deactivate' :
                            'activate' }}</b> {{ selectedTenantForActivation.email }}?
                    </p>
                    <v-card-actions class="justify-center">
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="activateDeactivate()"
                        >
                            <span
                                v-if="selectedTenantForActivation.status === 'active'"
                                style="color:red"
                            >Deactivate</span>
                            <span v-else>Activate</span>
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="show.confirmActivationDialog = false"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-dialog
                v-model="show.cancelInvoice"
                width="400"
            >
                <div class="dialog-header">
                    {{ $t('general.cancel_header') }}
                </div>
                <v-card
                    style="padding:10px;"
                    v-click-outside="resetInvoiceCancelation"
                >
                    <v-text-field
                        v-model="cancelationReason"
                        :label="$t('general.cancel_reason')"
                        append-icon="far fa-search"
                    />
                    <v-card-actions class="justify-center">
                        <v-btn
                            :loading="cancelInvoiceClicked"
                            color="blue darken-1"
                            text
                            @click="cancelInvoice()"
                        >
                            {{ $t('general.cancel') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                width="900"
                v-model="show.logs"
            >
                <div class="dialog-header">
                    {{ $t('general.logs') }}
                </div>
                <div class="dialog-body overflow-auto">
                    <Activity :logs="logs" />
                </div>
            </v-dialog>

            <v-dialog
                v-model="show.cancelPayment"
                width="400"
            >
                <div class="dialog-header">
                    {{ $t('general.cancel_header') }}
                </div>
                <v-card
                    style="padding:10px;"
                    v-click-outside="resetInvoiceCancelation"
                >
                    <v-text-field
                        v-model="cancelationReason"
                        :label="$t('general.cancel_reason')"
                        append-icon="far fa-search"
                    />
                    <v-card-actions class="justify-center">
                        <v-btn
                            :loading="cancelPaymentClicked"
                            color="blue darken-1"
                            text
                            @click="cancelPayment()"
                        >
                            {{ $t('general.cancel') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="show.updatePayment"
                width="350"
            >
                <div>
                    <v-card>
                        <div class="dialog-header">
                            {{ $t('companies.payments.dialog') }}
                        </div>
                        <div style="padding:4%">
                            <div style="text-align:center">
                                <v-date-picker
                                    v-model="payment.update_date"
                                    label="Date"
                                >
                                </v-date-picker>
                            </div>
                            <v-text-field
                                v-model="payment.amount_with_taxes"
                                :label="$t('general.amount')"
                            ></v-text-field>
                            <v-text-field
                                v-model="payment.referenceNumber"
                                :label="$t('companies.payments.reference_number')"
                            ></v-text-field>
                            <div style="text-align:center">
                                <v-btn
                                    outlined
                                    @click="updatePayment()"
                                >{{ $t('general.update') }}</v-btn>
                            </div>
                        </div>
                    </v-card>
                </div>
            </v-dialog>

            <div>
                <v-dialog
                    v-model="show.deletePaymentDialog"
                    width="400"
                >
                    <div class="dialog-header">
                        {{ $t('general.delete_header') }}
                    </div>
                    <v-card style="padding:10px;">
                        <v-card-text class="justify-center">
                            {{ $t('companies.payments.action_delete_message') }}
                        </v-card-text>
                        <v-card-actions class="justify-center">

                            <v-btn
                                color="blue darken-1"
                                text
                                @click="show.deletePaymentDialog = false"
                            >
                                {{ $t('general.close') }}
                            </v-btn>
                            <v-btn
                                :loading="deletePaymentClicked"
                                color="red darken-1"
                                text
                                @click="deletePayment()"
                            >
                                {{ $t('general.delete') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>

            <div>
                <v-dialog
                    v-model="show.addModule"
                    width="400"
                >
                    <div class="dialog-header">
                        {{ $t('modules.modules') }}
                    </div>
                    <v-card style="padding:10px;">
                        <v-card-text
                            v-if="modules.length > 0"
                            class="justify-start"
                        >
                            <v-switch
                                v-for="(item, index) in modules"
                                :key="index"
                                :label="$t(`modules.${item.name}`)"
                                v-model="item.active"
                                inset
                                @click="openModuleWarningModal(item)"
                            ></v-switch>

                        </v-card-text>
                        <div
                            class="justify-start pa-4"
                            v-if="modules.length == 0"
                        > {{ $t('modules.noModules') }} </div>
                    </v-card>
                </v-dialog>
            </div>

            <div>
                <v-dialog
                    v-model="show.addIntegration"
                    width="400"
                >
                    <div class="dialog-header">
                        {{ $t('integrations.integrations') }}
                    </div>
                    <v-card style="padding:10px;">
                        <v-card-text
                            v-if="integrations.length > 0"
                            class="justify-start"
                        >
                            <v-switch
                                v-for="(item, index) in integrations"
                                :key="index"
                                :label="item.name.replaceAll('_', ' ')"
                                v-model="item.active"
                                inset
                                @click="openIntegrationWarningModal(item)"
                            ></v-switch>

                        </v-card-text>
                        <div
                            class="justify-start pa-4"
                            v-if="integrations.length == 0"
                        > {{ $t('integrations.noIntegrations') }} </div>

                    </v-card>
                </v-dialog>
            </div>
        </template>

        <v-dialog
            v-model="show.warningAddModule"
            width="520"
        >
            <div class="dialog-header">
                {{ $t('general.warning') }}
            </div>
            <v-card style="padding:10px;">
                <v-card-text class="justify-start mt-6">
                    {{ this.$t('modules.activate_warning_message') }}
                </v-card-text>
                <div class="d-flex justify-space-between">
                    <v-btn
                        class="mt-7 align-center"
                        width="200px"
                        color="red"
                        outlined
                        @click="show.warningAddModule = false"
                    >
                        {{ this.$t('general.close') }}
                    </v-btn>
                    <v-btn
                        class="mt-7 align-center"
                        width="200px"
                        color="primary"
                        outlined
                        @click="activateModule()"
                    >
                        {{ this.$t('general.activate') }}
                    </v-btn>
                </div>

            </v-card>
        </v-dialog>

        <v-dialog
            v-model="show.warningAddIntegration"
            width="520"
        >
            <div class="dialog-header">
                {{ $t('general.warning') }}
            </div>
            <v-card style="padding:10px;">
                <v-card-text class="justify-start mt-6">
                    {{ this.$t('integrations.activate_warning_message') }}
                </v-card-text>
                <div class="d-flex justify-space-between">
                    <v-btn
                        class="mt-7 align-center"
                        width="200px"
                        color="red"
                        outlined
                        @click="show.warningAddIntegration = false"
                    >
                        {{ this.$t('general.close') }}
                    </v-btn>
                    <v-btn
                        class="mt-7 align-center"
                        width="200px"
                        color="primary"
                        outlined
                        @click="activateIntegration()"
                    >
                        {{ this.$t('general.activate') }}
                    </v-btn>
                </div>

            </v-card>
        </v-dialog>

        <v-dialog
            v-model="show.warningDisableModule"
            width="520"
        >
            <div class="dialog-header">
                {{ this.$t('modules.module_termination') }}
            </div>
            <v-card style="padding:17px;">
                <v-text-field
                    :label="this.$t('modules.terminate_reason_message')"
                    v-model="moduleTerminationReason"
                ></v-text-field>
                <div class="d-flex justify-space-between">
                    <v-btn
                        class="mt-3 align-center"
                        width="200px"
                        color="red"
                        outlined
                        @click="show.warningDisableModule = false"
                    >
                        {{ this.$t('general.close') }}
                    </v-btn>
                    <v-btn
                        class="mt-3 align-center"
                        width="200px"
                        color="primary"
                        outlined
                        @click="disableModule"
                    >
                        {{ this.$t('general.terminate') }}
                    </v-btn>
                </div>

            </v-card>
        </v-dialog>

        <v-dialog
            v-model="show.warningDisableIntegration"
            width="520"
        >
            <div class="dialog-header">
                {{ this.$t('integrations.integration_termination') }}
            </div>
            <v-card style="padding:17px;">
                <v-text-field
                    :label="this.$t('integrations.terminate_reason_message')"
                    v-model="integrationTerminationReason"
                ></v-text-field>
                <div class="d-flex justify-space-between">
                    <v-btn
                        class="mt-3 align-center"
                        width="200px"
                        color="red"
                        outlined
                        @click="show.warningDisableIntegration = false"
                    >
                        {{ this.$t('general.close') }}
                    </v-btn>
                    <v-btn
                        class="mt-3 align-center"
                        width="200px"
                        color="primary"
                        outlined
                        @click="disableIntegration"
                    >
                        {{ this.$t('general.terminate') }}
                    </v-btn>
                </div>

            </v-card>
        </v-dialog>

        <v-dialog
            v-model="show.moduleHistory"
            width="690"
        >
            <div class="dialog-header">
                {{ this.$t('modules.moduleHistory') }}
            </div>
            <v-card style="padding:14px;">
                <template v-if="moduleHistory.length > 0">
                    <v-data-table
                        :headers="moduleHistoryHeaders"
                        :items="moduleHistory"
                        hide-default-footer
                        class="elevation-1"
                    >
                        <template v-slot:item="{ moduleHistoryHeaders, item }">
                            <tr>

                                <td>{{ item.package.name.replaceAll('_', ' ') }}</td>
                                <td>{{ item.started_at | dateFromUtc }}</td>
                                <td>{{ item.terminated_at | dateFromUtc }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </template>
                <div v-if="moduleHistory.length == 0"> {{ $t('modules.noModules') }} </div>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="show.integrationHistory"
            width="690"
        >
            <div class="dialog-header">
                {{ this.$t('integrations.integrationHistory') }}
            </div>
            <v-card style="padding:14px;">
                <template v-if="integrationsHistory.length > 0">
                    <v-data-table
                        :headers="integrationHistoryHeaders"
                        :items="integrationsHistory"
                        hide-default-footer
                        class="elevation-1"
                    >
                        <template v-slot:item="{ integrationsHistoryHeaders, item }">
                            <tr>

                                <td>{{ item.package.name.replaceAll('_', ' ') }}</td>
                                <td>{{ item.started_at | dateFromUtc }}</td>
                                <td>{{ item.terminated_at | dateFromUtc }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </template>
                <div v-if="integrationsHistory.length == 0"> {{ $t('integrations.noIntegrations') }} </div>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>


import ContractForm from "@/views/admin/Company/ContractForm";
import TerminateContract from "@/views/admin/Company/TerminateContract";
import i18n from '@/i18n'
import Activity from "../Activity/Activity.vue";


export default {
    components: {
        Activity,
        ContractForm,
        TerminateContract,
    },
    data() {
        return {
            companyDetails: {
                name: "",
                PIB: "",
                amount_due: ""
            },
            tenantHeaders: [
                { text: this.$t('general.email'), value: "email" },
                { text: this.$t('general.name'), value: "name" },
                { text: this.$t('general.phone'), value: "phone" },
                { text: this.$t('general.status'), value: "status" },
                { text: "", value: "options" },
            ],
            contractHeaders: [
                { text: this.$t('general.email'), value: "tenant.email" },
                { text: this.$t('general.package_name'), value: "contract.name" },
                { text: this.$t('general.price'), value: "price" },
                { text: this.$t('general.discount'), value: "discount" },
                { text: this.$t('general.num_of_allowed_modules'), value: "num_of_allowed_modules" },
                { text: this.$t('general.activeModules'), value: "activeModules" },
                { text: this.$t('general.started_at'), value: "started_at" },
                { text: this.$t('general.expires_at'), value: "expires_at" },
                { text: this.$t('general.terminated'), value: "terminated_at" },
                { text: this.$t('general.reseller'), value: "reseller" },
                { text: "", value: "options" },
            ],
            invoiceHeaders: [
                { text: this.$t('general.start_date'), value: "billing_start_date" },
                { text: this.$t('general.end_date'), value: "billing_end_date" },
                { text: this.$t('general.total_amount'), value: "amount_with_taxes" },
                { text: this.$t('general.link'), value: "url" },
                { text: this.$t('general.actions'), value: "" },
            ],
            paymentHeaders: [
                { text: this.$t('general.total_amount'), value: "amount_due" },
                { text: this.$t('general.paid_at'), value: "paid_at" },
                { text: this.$t('general.reference_number'), value: "reference_number" },
                { text: this.$t('general.created_at'), value: "created_at" },
                { text: this.$t('general.actions'), value: "" },
            ],
            contracts: [],
            show: {
                contracts: false,
                terminateDialog: false,
                activateContractDialog: false,
                confirmDialog: false,
                paymentDialog: false,
                confirmActivationDialog: false,
                createInvoiceDialog: false,
                cancelInvoice: false,
                logs: false,
                cancelPayment: false,
                updatePayment: false,
                deletePaymentDialog: false,
                addModule: false,
                warningAddModule: false,
                warningDisableModule: false,
                moduleHistory: false,
                addIntegration: false,
                warningAddIntegration: false,
                warningDisableIntegration: false,
                integrationHistory: false
            },
            selectedItem: {
                email: ""
            },
            loading: false,
            invoiceLoading: false,
            paymentLoading: false,
            pagination: {
                sortBy: [""],
                itemsPerPage: 20,
                page: 1,
                sortDesc: [true],
            },
            invoicePagination: {
                sortBy: [""],
                itemsPerPage: 10,
                page: 1,
                sortDesc: [true],
            },
            paymentPagination: {
                sortBy: [""],
                itemsPerPage: 10,
                page: 1,
                sortDesc: [true],
            },
            total: 0,
            invoiceTotal: 0,
            paymentTotal: 0,
            filter: "",
            tempTenants: [],
            tenantContracts: [],
            invoices: [],
            payments: [],
            terminationReason: "",
            selectedContractId: null,
            selectedContract: {
                tenant: {
                    email: ""
                },
                contract: {
                    name: ""
                }
            },
            selectedOption: "",
            selectedTenantForActivation: {},
            reseller: "",
            tempReseller: "",
            payment: {
                id: "",
                amount_with_taxes: 0,
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                update_date: "",
                referenceNumber: ""
            },
            createInvoiceDate: [],
            nowDate: new Date().toISOString().slice(0, 10),
            resellers: [],
            invoiceForCancelation: {},
            paymentForCancelation: {},
            paymentForUpdating: {},
            paymentForDeletion: {},
            cancelationReason: '',
            loadingContainer: false,
            tempCompanyDetails: {},
            creatingInvoiceClicked: false,
            editEmail: '',
            editPhone: '',
            cancelInvoiceClicked: false,
            logs: [],
            cancelPaymentClicked: false,
            updatePaymentClicked: false,
            deletePaymentClicked: false,
            modules: [],
            integrations: [],
            moduleId: null,
            integrationId: null,
            currentContract: null, //For determine contract for adding modules
            contractableId: null,
            moduleTerminationReason: null,
            integrationTerminationReason: null,
            moduleHistory: [],
            moduleHistoryHeaders: [
                { text: this.$t('general.name'), sortable: false, value: 'module.name' },
                { text: this.$t('general.active'), sortable: false, value: 'started_at' },
                { text: this.$t('general.terminated'), sortable: false, value: 'terminated_at' },
            ],
            integrationsHistory: [],
            integrationHistoryHeaders: [
                { text: this.$t('general.name'), sortable: false, value: 'integration.name' },
                { text: this.$t('general.active'), sortable: false, value: 'started_at' },
                { text: this.$t('general.terminated'), sortable: false, value: 'terminated_at' },
            ],
        }
    },
    mounted() {
        ApiService.get(`/admin/companies/${this.$route.params.id}`).then(
            response => {
                this.companyDetails = response.data;
                this.editEmail = this.companyDetails.email;
                this.editPhone = this.companyDetails.phone;
                this.tempTenants = this.companyDetails.tenants;

                //Don't call this api's unless user is admin or main_reseller
                if (this.isAdmin || this.isMainReseller) {
                    this.getInvoices();
                }

                this.getContracts();
                this.getPayments();
                this.getResellers();
                this.loadingContainer = true;

            }
        ).catch(
            error => {
                if (error.response.status === 403) {
                    this.$snackbar('You are not authorized to perform this action.', 'error');
                }
            }
        );
    },
    watch: {
        pagination: {
            deep: true,
            handler() {
                this.getContracts();
            },
        },
        invoicePagination: {
            deep: true,
            handler() {
                this.getInvoices();
            },
        },
        paymentPagination: {
            deep: true,
            handler() {
                this.getPayments();
            },
        },
    },
    computed: {
        isAdmin() {
            return this.$store.state.user.role === 'admin';
        },

        isMainReseller() {
            return this.$store.state.user.role === 'main_reseller';
        },

        canEdit() {
            return this.isAdmin || this.isMainReseller;
        },

        isGoranOrIvanReseller() {
            //If user dont have reseller return false
            if (!this.$store.state.user.reseller) return false
            //By their reseller hash we check if user is Goran or Ivan
            return this.$store.state.user.reseller.hash === 'lm360rh852' || this.$store.state.user.reseller.hash === 'oa146vo061'
        },

        showContractsExpansionPanel() {
            return this.isAdmin || this.isMainReseller || this.isGoranOrIvanReseller
        },
    },
    methods: {
        getContracts() {

            const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

            const params = {
                page: page,
                itemsPerPage: itemsPerPage,
                sortBy: sortBy[0] ? sortBy[0] : "created_at",
                sortOrder: sortDesc[0] ? "desc" : "asc",
            };

            this.loading = true;

            ApiService.get(`/admin/companies/${this.companyDetails.id}/tenant-contracts`, { params }).then(response => {
                this.tenantContracts = response.data.data;
                this.total = response.data.total;
                this.loading = false;
            });
        },
        /*
          Looping trough modules and finding what are active modules to show user in switch what is active above all modules
         */
        getModules(contract) {
            ApiService.get(`/admin/modules`).then(async response => {
                await response.data.modules.forEach(module => {
                    //This Make module disabled
                    module.active = false;
                    //Conditionally apply that is active if can be found in active modules array
                    contract.active_modules.forEach(activeModule => {
                        if (activeModule.package.id == module.id) {
                            module.active = true;
                            //Passing contratable id for purpose of disableing
                            module.contractableId = activeModule.id
                        }
                    })
                })
                this.modules = response.data.modules
            }).catch(error => {
                console.log(error)
            })
        },

        /*
          Looping trough integrations and finding what are active integrations to show user in switch what is active above all integrations
         */
        getIntegrations(contract) {
            ApiService.get(`/admin/integrations`).then(async response => {
                await response.data.integrations.forEach(integration => {
                    //This makeIntegration disabled
                    integration.active = false;
                    //Conditionally apply that is active if can be found in active integrations array
                    contract.active_integrations.forEach(activeIntegration => {
                        if (activeIntegration.package.id == integration.id) {
                            integration.active = true;
                            //Passing contratable id for purpose of disableing
                            integration.contractableId = activeIntegration.id
                        }
                    })
                })
                this.integrations = response.data.integrations
            }).catch(error => {
                console.log(error)
            })
        },

        getInvoices() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.invoicePagination;

            const params = {
                page: page,
                itemsPerPage: itemsPerPage,
                sortBy: sortBy[0] ? sortBy[0] : "created_at",
                sortOrder: sortDesc[0] ? "desc" : "asc",
            };

            ApiService.get(`/admin/companies/${this.companyDetails.id}/invoices`, { params }).then(response => {
                this.invoices = response.data.data;
                this.invoiceTotal = response.data.total;
                // Rounding on two decimals
                if (this.invoices.length > 0) {
                    let length = this.invoices[0].amount_with_taxes.toString().length;
                    this.payment.amount_with_taxes = this.invoices[0].amount_with_taxes.substring(0, length - 2);
                }
                this.invoiceLoading = false;
            });

        },
        getPayments() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.paymentPagination;

            const params = {
                page: page,
                itemsPerPage: itemsPerPage,
                sortBy: sortBy[0] ? sortBy[0] : "created_at",
                sortOrder: sortDesc[0] ? "desc" : "asc",
            };

            ApiService.get(`/admin/companies/${this.companyDetails.id}/payments`, { params }).then(response => {
                this.payments = response.data.data;
                this.paymentTotal = response.data.total;
                this.paymentLoading = false;
            });

        },
        getResellers() {
            ApiService.get("/admin/resellers?locale=sr").then(
                response => response.data.resellers.forEach(reseller => this.resellers.push(reseller.name))
            );
        },
        searchContracts(filters) {
            if (filters) {
                ApiService.get(`/admin/contracts?name=${filters.nameFilter}`).then(response => this.contracts = response.data);
            }
        },
        showContracts(item, option) {
            this.selectedOption = option;
            this.selectedItem = item;
            this.show.contracts = true;
            ApiService.get(`/admin/contracts?app=${item.app}`).then(response => this.contracts = response.data);
        },
        showCreateInvoiceDialog() {
            this.show.createInvoiceDialog = true;
            this.createInvoiceDate = [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)];
        },
        searchTenants() {
            this.filter = this.filter.toLowerCase();

            this.tempTenants = this.companyDetails.tenants.filter(tenant =>
                tenant.email.toLowerCase().indexOf(this.filter) > -1 ||
                tenant.name.toLowerCase().indexOf(this.filter) > -1 ||
                tenant.phone.toLowerCase().indexOf(this.filter) > -1
            );
        },
        closeContractFormDialog(data) {
            // console.log('ovdje je ono sto ti ne dodaje prvi red u tabeli za contracte i tabeli za invoice, kada su prazne u pocetku');
            this.show.contracts = false;
            this.getContracts();
            this.total = this.tenantContracts.length;
        },
        closeDialogChangePackage(data) {

            data.contracts.forEach(contract => this.tenantContracts.unshift(contract))

            data.terminatedContracts.forEach(contract => {
                const index = this.tenantContracts.findIndex(item => item.id === contract.id)

                this.tenantContracts[index].terminated_at = contract.terminated_at;
                this.tenantContracts[index].termination_reason = contract.termination_reason;

            })
            this.show.contracts = false;

        },
        showTerminateDialog(id) {
            this.selectedContract = this.tenantContracts.find(item => item.id == id);
            this.terminationReason = this.selectedContract.termination_reason;
            this.show.terminateDialog = true;
        },
        //Passing contract id for specific dialog and opening dialog
        showContractActivationDialog(id) {
            this.selectedContractId = id
            this.show.activateContractDialog = true;
        },
        showActivationDialog(tenant) {
            this.show.confirmActivationDialog = true;
            this.selectedTenantForActivation = tenant;
        },
        showModuleAddDialog(item) {
            this.currentContract = item;
            this.getModules(this.currentContract);
            this.show.addModule = true;
        },
        showIntegrationsAddDialog(item) {
            this.currentContract = item;
            this.getIntegrations(this.currentContract);
            this.show.addIntegration = true;
        },
        terminateContract(data) {

            const indexOfSelectedContract = this.tenantContracts.findIndex(item => item.id === data.id)

            ApiService.post(`/admin/tenant-contracts/${data.id}/terminate`, {
                termination_reason: data.terminationReason,
                terminated_at: data.terminated_at
            }).then(response => {
                this.getContracts();
                if (response.status === 200) {
                    this.show.terminateDialog = false;
                    this.$snackbar('Contract terminated', 'success');
                }
            }).catch(
                error => {
                    if (error.response.status === 403) {
                        this.$snackbar('You are not authorized to perform this action.', 'error');
                    }
                }
            );
        },


        // Activating Contract
        async activateContract() {
            try {
                //Passing contract id to api call
                await ApiService.post(`/admin/tenant-contracts/${this.selectedContractId}/activate`)
                this.getContracts()
                this.show.activateContractDialog = false;
                this.$snackbar('Contract activated', 'success');
            } catch (error) {
                this.show.activateContractDialog = false;
                // Error response or request error
                if (error.response) {
                    this.$snackbar(error.response.data.message, 'error');
                } else {
                    //Pop up error message
                    this.$snackbar(`Error occurred ${error.message}`, 'error');
                }
            }
        },

        openChangeResellerDialog(reseller, id) {
            this.reseller = { contract_id: id, name: reseller };
            this.show.confirmDialog = true;
        },
        changeReseller() {

            const index = this.tenantContracts.findIndex(item => item.id === this.reseller.contract_id)

            ApiService.patch(`/admin/tenant-contracts/${this.reseller.contract_id}/change-reseller`, { reseller: this.reseller.name })
                .then(response => {
                    if (response.status === 200) {
                        this.reseller = {};
                        this.tenantContracts[index].discount = response.data.discount;
                        this.show.confirmDialog = false;
                        this.$snackbar('Reseller changed succesfully', 'success');
                    }
                }).catch(
                    error => {
                        if (error.response.status === 403) {
                            this.$snackbar('You are not authorized to perform this action.', 'error');
                        }
                    }
                );
        },
        resetSelectReseller() {
            this.show.confirmDialog = false;
            const index = this.tenantContracts.findIndex(item => item.id === this.reseller.contract_id)
            this.tenantContracts[index].reseller = this.tempReseller
        },
        async resendInvoice(id, type) {

            const url = type === 'invoice' ? `/admin/companies/${this.companyDetails.id}/invoices/${id}/resend`
                : `/admin/tenant-contracts/${id}/send-last-invoice`

            try {
                await ApiService.post(url).then(response => {
                    if (response.status === 200) {
                        this.$snackbar('Invoices resended', 'success');
                        return;
                    }
                });
            }
            catch (error) {
                this.$snackbar('Sending invoice email failed', 'error');
            }
        },
        openCancelInvoiceDialog(item) {
            this.show.cancelInvoice = true;
            this.invoiceForCancelation = item;
            this.invoiceForCancelation = item;
        },
        resetInvoiceCancelation() {
            this.cancelationReason = "";
        },
        async cancelInvoice() {

            const url = `/admin/companies/${this.companyDetails.id}/invoices/${this.invoiceForCancelation.id}/cancel`;
            this.cancelInvoiceClicked = true;

            try {
                await ApiService.post(url, { cancel_comment: this.cancelationReason }).then(response => {
                    if (response.status === 200) {
                        this.show.cancelInvoice = false;

                        const selectedIndex = this.invoices.findIndex(item => item.id === this.invoiceForCancelation.id);
                        this.invoices[selectedIndex] = response.data.cancelInvoices.cancelInvoice;
                        this.invoices.unshift(response.data.cancelInvoices.canceledInvoice);
                        this.companyDetails.amount_due = response.data.amount_due
                        this.cancelInvoiceClicked = false;

                        this.$snackbar('Invoices canceled', 'success');
                        this.resetInvoiceCancelation();

                        return;
                    }
                });
            }
            catch (error) {
                error.response.data.errors.forEach(error => {
                    this.cancelInvoiceClicked = false;
                    this.$snackbar(error.general, 'error');
                })
            }
        },
        async completePayment() {

            const params = {
                amount: this.payment.amount_with_taxes,
                reference_number: this.payment.referenceNumber,
                paid_at: this.payment.date,
            }

            try {
                await ApiService.post(`/admin/companies/${this.companyDetails.id}/payments`, params).then(response => {
                    if (response.status === 200) {
                        this.payments.unshift(response.data)
                        this.paymentTotal = this.payments.length;
                        this.$snackbar('Payment successful', 'success');
                        this.show.paymentDialog = false;
                        this.payment.referenceNumber = "";
                        this.companyDetails.amount_due = response.data.company.amount_due;
                        return;
                    }
                });
            }
            catch (error) {
                console.log(error);
            }
        },
        openDeletePaymentDialog(item) {
            this.show.deletePaymentDialog = true;
            this.paymentForDeletion = item;
        },

        async deletePayment() {
            this.deletePaymentClicked = true;

            try {
                await ApiService.delete(`/admin/companies/${this.companyDetails.id}/payments/${this.paymentForDeletion.id}/delete`, {}).then(response => {
                    if (response.status === 200) {
                        const selectedIndex = this.payments.findIndex(i => i.id === this.paymentForDeletion.id);
                        this.payments.splice(selectedIndex, 1)
                        this.paymentTotal = this.payments.length;
                        this.$snackbar('Payment successfully deleted', 'success');
                        this.show.deletePaymentDialog = false;
                        this.payment.referenceNumber = "";
                        this.companyDetails.amount_due = response.data.company.amount_due;
                        return;
                    }
                });
            }
            catch (error) {
                this.deletePaymentClicked = false;
                if (error.response.status === 403) {
                    this.$snackbar('You are not authorized to perform this action.', 'error');
                } else {
                    for (const key in error.response.data.errors) {

                        if (error.response.data.errors.hasOwnProperty(key)) {

                            this.$snackbar(error.response.data.errors[key][0], 'error');
                        }
                    }
                }
            }
        },

        openUpdatePaymentDialog(item) {
            this.show.updatePayment = true;
            this.paymentForUpdating = item;
            this.payment.update_date = new Date(item.paid_at).toISOString().substr(0, 10);
            this.payment.referenceNumber = item.reference_number;
            this.payment.amount_with_taxes = item.amount;
            this.payment.id = item.id;

        },
        async updatePayment() {

            const params = {
                amount: this.payment.amount_with_taxes,
                reference_number: this.payment.referenceNumber,
                paid_at: this.payment.update_date,
            }

            try {
                await ApiService.put(`/admin/companies/${this.companyDetails.id}/payments/${this.payment.id}`, params).then(response => {
                    if (response.status === 200) {

                        const selectedIndex = this.payments.findIndex(item => item.id === this.payment.id);
                        Object.assign(this.payments[selectedIndex], response.data);

                        this.$snackbar('Payment successfully updated', 'success');
                        this.show.updatePayment = false;
                        this.payment.referenceNumber = "";
                        this.companyDetails.amount_due = response.data.company.amount_due;
                        return;
                    }
                });
            }
            catch (error) {
                if (error.response.status === 403) {
                    this.$snackbar('You are not authorized to perform this action.', 'error');
                } else {
                    for (const key in error.response.data.errors) {

                        if (error.response.data.errors.hasOwnProperty(key)) {

                            this.$snackbar(error.response.data.errors[key][0], 'error');
                        }
                    }
                }
            }
        },

        openCancelPaymentDialog(item) {
            this.show.cancelPayment = true;
            this.paymentForCancelation = item;
        },
        resetPaymentCancelation() {
            this.cancelationReason = "";
        },

        async cancelPayment() {

            const url = `/admin/companies/${this.companyDetails.id}/payments/${this.paymentForCancelation.id}/cancel`;
            this.cancelPaymentClicked = true;

            try {
                await ApiService.post(url, { cancel_comment: this.cancelationReason }).then(response => {
                    if (response.status === 200) {
                        this.show.cancelPayment = false;
                        const selectedIndex = this.payments.findIndex(item => item.id === this.paymentForCancelation.id);
                        this.payments[selectedIndex] = response.data.cancelPayment;
                        this.payments.unshift(response.data.cancelPayment.cancels);
                        this.companyDetails.amount_due = response.data.amount_due;
                        this.cancelPaymentClicked = false;

                        this.$snackbar('Payment canceled', 'success');
                        this.resetPaymentCancelation();

                        return;
                    }
                });
            }
            catch (error) {
                this.cancelPaymentClicked = false;
                this.show.cancelPayment = false;
                if (error.response.status === 403) {
                    this.$snackbar('You are not authorized to perform this action.', 'error');
                } else {
                    for (const key in error.response.data.errors) {

                        if (error.response.data.errors.hasOwnProperty(key)) {

                            this.$snackbar(error.response.data.errors[key][0], 'error');
                        }
                    }
                }

            }
        },
        activateDeactivate() {

            const index = this.companyDetails.tenants.findIndex(ten => ten.id === this.selectedTenantForActivation.id)

            ApiService.patch(`/admin/tenants/${this.selectedTenantForActivation.id}/activate `, {
                activate: this.selectedTenantForActivation.status === 'active' ? 0 : 1
            })
                .then(response => {
                    if (response.status === 200) {
                        this.companyDetails.tenants[index].status = response.data.tenant.status;
                        this.show.confirmActivationDialog = false;
                        this.$snackbar(`Status of ${response.data.tenant.email} changed succesfully`, 'success');
                    }
                }).catch(
                    error => {
                        if (error.response.status === 403) {
                            this.$snackbar('You are not authorized to perform this action.', 'error');
                        }
                    }
                );
        },
        async createInvoice() {

            this.creatingInvoiceClicked = true;


            let firstDate = new Date(this.createInvoiceDate[0]);
            let secondDate = new Date(this.createInvoiceDate[1]);

            const dates = firstDate < secondDate ? { startDate: firstDate.toISOString().substring(0, 10), endDate: secondDate.toISOString().substring(0, 10) }
                : { startDate: secondDate.toISOString().substring(0, 10), endDate: firstDate.toISOString().substring(0, 10) }

            try {
                const response = await ApiService.post(`/admin/companies/${this.companyDetails.id}/invoices`, { start_date: dates.startDate, end_date: dates.endDate, })
                    .then(response => {


                        if (response.status === 200) {
                            this.invoices.unshift(response.data.invoice);

                            this.companyDetails.amount_due = response.data.amount_due;
                            this.show.createInvoiceDialog = false;
                            this.creatingInvoiceClicked = false;
                            this.$snackbar('Invoice created', 'success');
                            this.getInvoices();
                        }

                    });
            }
            catch (error) {
                error.response.data.errors.forEach(error => this.$snackbar(error.general, 'error'))
            }
        },
        updateCompanyInfo(prop) {
            if (prop === 'email') this.companyDetails.email = this.editEmail;
            if (prop === 'phone') this.companyDetails.phone = this.editPhone;

            ApiService.patch(`/admin/companies/${this.companyDetails.id}`, this.companyDetails).then(response => {
                this.$snackbar(`${this.$t('general.' + prop)} ${this.$t('general.successfully_updated')}`, 'success');
            });
        },
        async showPaymentLogs(id) {
            const type = encodeURIComponent('App\\Models\\CompanyPayment');
            const response = await ApiService.get(`/admin/activity/${type}/${id}`);
            this.logs = response.data
            this.show.logs = true
        },
        async showInvoiceLogs(id) {
            const type = encodeURIComponent('App\\Models\\CompanyInvoice');
            const response = await ApiService.get(`/admin/activity/${type}/${id}`);
            this.logs = response.data
            this.show.logs = true
        },
        async showTenantContractLogs(id) {
            const response = await ApiService.get(`admin/tenant-contracts/${id}/activity`);
            this.logs = response.data.activities
            this.show.logs = true
        },
        async activateModule() {
            try {
                const response = await ApiService.post(`admin/tenant-contracts/${this.currentContract.id}/add-module/${this.moduleId}`);
                this.currentContract = response.data.contract
                this.getModules(this.currentContract);
                this.getContracts();
                this.show.warningAddModule = false;
                this.$snackbar(`${this.$t('modules.successfully_added')}`, 'success');
            } catch (e) {
                console.log(e)
                this.$snackbar(e.response.data.message, 'error');
            }
        },
        async disableModule() {
            try {
                const response = await ApiService.post(`admin/tenant-contracts/${this.currentContract.id}/disable-module/${this.contractableId}`, {
                    termination_reason: this.moduleTerminationReason
                });
                this.currentContract = response.data.contract
                this.getModules(this.currentContract);
                this.getContracts();
                this.moduleTerminationReason = null;
                this.show.warningDisableModule = false;
                this.$snackbar(`${this.$t('modules.successfully_disabled')}`, 'success');
            } catch (e) {
                console.log(e.data.message)
            }
        },
        async activateIntegration() {
            try {
                const response = await ApiService.post(`admin/tenant-contracts/${this.currentContract.id}/add-integration/${this.integrationId}`);
                this.currentContract = response.data.contract
                this.getIntegrations(this.currentContract);
                this.getContracts();
                this.show.warningAddIntegration = false;
                this.$snackbar(`${this.$t('integrations.successfully_added')}`, 'success');
            } catch (e) {
                console.log(e)
                this.$snackbar(e.response.data.message, 'error');
            }
        },
        async disableIntegration() {
            try {
                const response = await ApiService.post(`admin/tenant-contracts/${this.currentContract.id}/disable-integration/${this.contractableId}`, {
                    termination_reason: this.integrationTerminationReason
                });
                this.currentContract = response.data.contract
                this.getIntegrations(this.currentContract);
                this.getContracts();
                this.integrationTerminationReason = null;
                this.show.warningDisableIntegration = false;
                this.$snackbar(`${this.$t('integrations.successfully_disabled')}`, 'success');
            } catch (e) {
                console.log(e.data.message)
            }
        },
        async openModuleWarningModal(item) {
            //Prevent modules(switch) for default changes
            this.getModules(this.currentContract);
            //Check what is action and change using module
            this.moduleId = item.id;

            this.contractableId = item.contractableId;
            if (item.active == true) this.show.warningAddModule = true
            if (item.active == false) this.show.warningDisableModule = true
        },
        async openIntegrationWarningModal(item) {
            //Prevent integrations(switch) for default changes
            this.getIntegrations(this.currentContract);
            //Check what is action and change using integration
            this.integrationId = item.id;

            this.contractableId = item.contractableId;
            if (item.active == true) this.show.warningAddIntegration = true
            if (item.active == false) this.show.warningDisableIntegration = true
        },
        showModuleHistoryDialog(item) {
            //Getting all modules
            this.moduleHistory = item.modules
            this.show.moduleHistory = true
        },
        showIntegrationHistoryDialog(item) {
            //Getting all Integrations
            this.integrationsHistory = item.integrations
            this.show.integrationHistory = true
        },
    },
}
</script>

<style>
.dialog-header {
    padding: 16px;
    background: #363c4a;
    color: white;
}

.table-header {
    background-color: white;
    padding-left: 1%
}

.vl {
    border-left: 1px solid #363c4a;
}

.content {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.dialog-body {
    background: white;
    padding: 20px;
}
</style>
