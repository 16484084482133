import { mapGetters } from "vuex";
import Store from "@/store";
import moment from "moment";

export default {
    data() {
        return {};
    },

    computed: {
        // Map store data to computed properties
        // to be available in all components
        ...mapGetters(["user", "appConfig", "locales"])
    },

    filters: {
        dateFromUtc(date, seconds = "") {
            if (!date) return "";
            if (!(date instanceof moment)) {
                date = moment.utc(date).local();
            }

            return date.format("D MMM YYYY, HH:mm" + seconds);
        },
        // dateFromUtcSeconds(date) {
        //     if (!date) return "";
        //     if(!(date instanceof moment)) {
        //         date = moment.utc(date).local()
        //     }

        //     return date.format('D MMM YYYY, HH:mm:ss')
        // },
        twoDecimals(value) {
            return Number(value).toFixed(2);
        },
        onlyDate(value) {
            return value[10] === ","
                ? value.substring(0, 10)
                : value.substring(0, 11);
        },
        upperCase(value) {
            return value.toUpperCase();
        }
    }
};
