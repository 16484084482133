<template>
  <v-dialog
    v-if="value"
    :value="value"
    width="750"
    @input="$emit('input', false)"
  >
    <v-card>
      <v-card-title class="dialog-header">
        {{ $t('general.change_tax_rates') }}
      </v-card-title>

      <v-card-text class="overflow-auto mt-4">
        <v-select
          v-model="selectedOption"
          dense
          outlined
          hide-details
          :label="$t('general.select_option')"
          :items="options"
          class="my-4"
          @change="selectedTaxRate = null"
        />

        <template v-if="[1, 2, 3].includes(selectedOption)">
          <v-select
            v-model="selectedTaxRate"
            :items="enrichedTaxRates"
            dense
            outlined
            hide-details
            :label="$t('general.select_tax_rate')"
            item-text="name"
            item-value="id"
            class="mb-4"
          />

          <v-switch
            v-if="[2, 3].includes(selectedOption)"
            v-model="includeWithoutCategory"
            :label="$t('general.include_products_without_category')"
          />
        </template>

        <v-select
          v-if="selectedOption === 2"
          v-model="selectedCategories"
          :items="productCategories"
          :label="$t('general.select_product_category') + `(${$t('general.multiple')})`"
          :disabled="!selectedTaxRate"
          dense
          outlined
          multiple
          clearable
          small-chips
          hide-details
          deletable-chips
          item-text="name"
          item-value="id"
          class="mb-4"
        >
          <template #prepend-item>
            <v-list-item
              ripple
              @mousedown.prevent
              @click="toggle"
            >
              <v-list-item-action>
                <v-icon :color="selectedCategories.length > 0 ? 'indigo darken-4' : ''">
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Select All
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>

        <v-select
          v-if="selectedOption === 3"
          v-model="selectedType"
          :items="productTypes"
          :label="$t('general.select_product_type')"
          :disabled="!selectedTaxRate"
          dense
          outlined
          hide-details
          item-text="name"
          item-value="value"
          class="mb-4"
        />

        <v-row v-if="selectedOption === 4">
          <v-col cols="5">
            <v-select
              v-model="selectedTaxRate"
              :items="enrichedTaxRates"
              dense
              outlined
              hide-details
              :label="$t('general.select_tax_rate')"
              item-text="name"
              item-value="id"
            />
          </v-col>

          <v-col
            cols="2"
            class="d-flex justify-center align-center"
          >
            <v-icon>mdi mdi-arrow-right-bold</v-icon>
          </v-col>

          <v-col cols="5">
            <v-select
              v-model="newProductTaxRate"
              :items="productTaxRates"
              :label="$t('general.select_product_tax_rate')"
              :disabled="!selectedTaxRate"
              dense
              outlined
              hide-details
              item-text="name"
              item-value="id"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          outlined
          :disabled="disableSaveButton"
          @click="save"
        >
          {{ $t('general.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty } from 'lodash';

export default {
  props: {
    value: {
      type: Boolean,
      required: false
    },
    curTenant: {
      type: Object,
      default: () => { },
    },
  },

  created() {
    ApiService.get(`/admin/tenants/${this.curTenant.id}/products/product-categories`)
      .then((response) => {
        this.productCategories = response.data.product_categories
      })
  },

  data: () => ({
    productCategories: [],
    selectedOption: 1,
    selectedTaxRate: null,
    selectedCategories: [],
    selectedType: null,
    newProductTaxRate: null,
    includeWithoutCategory: false,
  }),

  computed: {
    ...mapGetters(['taxRates']),

    enrichedTaxRates() {
      return this.taxRates.map(rate => ({
        ...rate,
        name: `${rate.label} - ${parseInt(rate.rate, 10)}%`,
      }));
    },

    options() {
      return [
        { text: this.$t('general.all_products'), value: 1 },
        { text: this.$t('general.product_category'), value: 2 },
        { text: this.$t('general.produc_type'), value: 3 },
        { text: this.$t('general.switch_product_tax_rates'), value: 4 },
      ]
    },

    productTypes() {
      return [
        { name: this.$t('general.drinks'), value: 'drinks' },
        { name: this.$t('general.food'), value: 'food' },
        { name: this.$t('general.other'), value: 'other' },
      ]
    },

    productTaxRates() {
      return this.enrichedTaxRates.filter(rate => rate.id !== this.selectedTaxRate)
    },

    disableSaveButton() {
      if (this.selectedOption === 1) {
        return !this.selectedTaxRate
      }

      if (this.selectedOption === 2) {
        return !this.selectedTaxRate || isEmpty(this.selectedCategories)
      }

      if (this.selectedOption === 3) {
        return !this.selectedTaxRate || !this.selectedType
      }

      if (this.selectedOption === 4) {
        return !this.selectedTaxRate || !this.newProductTaxRate
      }
    },

    allCategories() {
      return this.selectedCategories.length === this.productCategories.length
    },
    someCategories() {
      return this.selectedCategories.length > 0 && !this.allCategories
    },

    icon() {
      if (this.allCategories) return 'mdi-close-box'
      if (this.someCategories) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },

  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.allCategories) {
          this.selectedCategories = []
        } else {
          const categories = this.productCategories.map(category => category.id)
          this.selectedCategories = categories.slice()
        }
      })
    },

    save() {
      if (this.selectedOption === 4) {
        ApiService.patch(`/admin/tenants/${this.curTenant.id}/products/update-tax-rate-by-tax-rate`, {
          tax_rate_id: this.selectedTaxRate,
          new_tax_rate_id: this.newProductTaxRate,
        })
          .then(() => {
            this.$emit('success')
            this.$snackbar(this.$t('general.tax_rate_updated'), 'success')
          }).catch((error) => {
            this.$snackbar(error, 'error')
          }).finally(() => {
            this.$emit('input', false)
          })

        return
      }

      let payload = {
        tax_rate_id: this.selectedTaxRate,
        include_without_category: this.includeWithoutCategory,
        product_categories: this.selectedCategories,
      }

      if (this.selectedOption === 1) {
        payload.include_without_category = true;
        // All categories should be sent on option 1
        payload.product_categories = this.productCategories.map(category => category.id)
      }

      if (this.selectedOption === 3) {
        payload.product_categories = this.productCategories.filter(category => category.type === this.selectedType)
          .map(category => category.id)
      }

      ApiService.patch(`/admin/tenants/${this.curTenant.id}/products/update-tax-rate-by-categories`, payload)
        .then(() => {
          this.$snackbar(this.$t('general.tax_rate_updated'), 'success')
          this.$emit('success')
        }).catch((error) => {
          this.$snackbar(error, 'error')
        }).finally(() => {
          this.$emit('input', false)
        })
    }
  }
}
</script>
