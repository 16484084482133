<template>
    <!-- Basic details -->
    <v-container>
        <div class="content">
            <v-progress-circular
                v-if="!loadingContainer"
                indeterminate
            />
        </div>

        <div v-if="loadingContainer">
            <v-row class="mb-4">
                <v-card
                    flat
                    width="600"
                >
                    <v-card-title>
                        {{ tenant.name }}
                    </v-card-title>

                    <v-card-text>
                        <v-text-field
                            v-model="tenant.email"
                            :label="$t('general.email')"
                            disabled
                        />

                        <template v-if="tenant.reseller">
                            <v-text-field
                                v-model="tenant.reseller.name"
                                :label="$t('general.reseller')"
                                disabled
                            />
                        </template>

                        <v-text-field
                            v-model="tenant.app"
                            :label="$t('general.app')"
                            disabled
                        />
                    </v-card-text>
                </v-card>
            </v-row>

            <v-row>
                <v-data-table
                    class="operations striped"
                    :headers="headers"
                    :hide-default-footer="true"
                    :items="tenant.cashRegisters"
                    :server-items-length="tenant.cashRegisters.length"
                    :loading="loading"
                    :mobile-breakpoint="0"
                >

                    <template v-slot:item="{ headers, item, index }">
                        <tr>
                            <td>{{ item.hash }} </td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.model }}</td>
                            <td>{{ item.manufacturer }}</td>
                            <td
                                v-if="item.settings"
                                class="payment-td"
                            >
                                <template v-for="(payment, name) in item.settings.payments">
                                    <span v-if="payment">
                                        {{ $t('payment_methods.' + name) }},
                                    </span>
                                </template>
                            </td>
                            <td v-else></td>
                            <td>{{ item.created_at | dateFromUtc }}</td>
                            <template v-if="item.latest_shift">
                                <td
                                    :style="item.latest_shift.closed_at ? 'color: red; cursor: pointer' : 'cursor: pointer'"
                                    @click="showShiftDetails(item)"
                                >
                                    {{ item.latest_shift.opened_at | dateFromUtc }}
                                </td>
                            </template>
                            <td v-else></td>
                            <td
                                class="w60"
                                align="center"
                            >
                                <v-menu
                                    bottom
                                    transition="slide-y-transition"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-if="canSee"
                                            v-on="on"
                                            icon
                                        >
                                            <v-icon>mdi mdi-dots-horizontal</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <template>
                                            <v-list-item
                                                :key="1"
                                                @click="openRemoveCashRegisterDialog(item)"
                                            >
                                                <v-list-item-action class="mr-4 flex justify-content-center">
                                                    <v-icon class="error--text">mdi mdi-delete-outline</v-icon>
                                                </v-list-item-action>
                                                <v-list-item-title>{{ $t('general.delete') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-divider />
                                            <v-list-item
                                                v-if="$i18n.locale === 'sr'"
                                                :key="2"
                                                @click="openConfigurePayments(item)"
                                            >
                                                <v-list-item-action class="mr-4 flex justify-content-center">
                                                    <v-icon>mdi mdi-credit-card-outline</v-icon>
                                                </v-list-item-action>
                                                <v-list-item-title>{{ $t('general.configure_payments')
                                                    }}</v-list-item-title>
                                            </v-list-item>
                                            <v-divider />
                                            <template v-if="item.latest_shift">
                                                <v-list-item
                                                    v-if="!item.latest_shift.closed_at"
                                                    :key="3"
                                                    @click="closeShift(item)"
                                                >
                                                    <v-list-item-action class="mr-4 flex justify-content-center">
                                                        <v-icon>mdi mdi-close-box</v-icon>
                                                    </v-list-item-action>
                                                    <v-list-item-title>{{ $t('general.close_shift')
                                                        }}</v-list-item-title>
                                                </v-list-item>
                                                <v-divider />
                                            </template>
                                        </template>
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-row>
        </div>

        <v-dialog
            v-model="show.removeCashRegister"
            width="400"
        >
            <div class="dialog-header">
                Brisanje naplatnog uređaja
            </div>
            <v-card style="padding:10px;">
                <v-card-actions class="justify-center">
                    <v-btn
                        depressed
                        color="error"
                        :loading="removeCashRegisterClicked"
                        @click="removeCashRegister()"
                    >
                        {{ $t('general.delete') }}
                    </v-btn>
                    <v-btn
                        depressed
                        :disabled="removeCashRegisterClicked"
                        @click="show.removeCashRegister = false"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="show.configurePayments"
            width="400"
        >
            <div class="dialog-header">
                {{ $t('general.configure_payments') }}
            </div>
            <v-card style="padding:10px;">
                <v-select
                    v-model="select"
                    :loading="selectedConfigurePayments"
                    :selectable="car => selectedConfigurePayments"
                    :items="[this.$t('cash_register.all_payments'), this.$t('cash_register.limited_payments')]"
                    label="Placanja"
                    @change="configurePayments($event)"
                >
                </v-select>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="show.shiftDetails"
            width="400"
        >
            <div class="dialog-header">
                {{ $t('general.shift_details') }}
            </div>
            <v-card
                v-if="selectedCashRegister.latest_shift"
                style="padding:10px;"
            >
                <p v-if="selectedCashRegister.latest_shift.opened_at">
                    Otvorena: <b>{{ selectedCashRegister.latest_shift.opened_at | dateFromUtc }}</b>
                </p>
                <p v-if="selectedCashRegister.latest_shift.closed_at">
                    Zatvorena: <b>{{ selectedCashRegister.latest_shift.closed_at | dateFromUtc }}</b>
                </p>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>


export default {
    components: {
    },
    data() {
        return {
            tenant: {},
            loadingContainer: false,
            pagination: {
                sortBy: ["created_at"],
                itemsPerPage: 100,
                page: 1,
                sortDesc: [true],
            },

            headers: [
                { text: this.$t('tenant.hash'), value: "hash" },
                { text: this.$t('tenant.name'), value: "name" },
                { text: this.$t('tenant.model'), value: "model" },
                { text: this.$t('tenant.manufacturer'), value: "manufacturer" },
                { text: this.$t('tenant.allowed_payment_methods'), value: "payments" },
                { text: this.$t('general.created_at'), value: "created_at" },
                { text: this.$t('general.latest_shift_opened'), value: "opened_at" },
                { text: '', value: "options" },
            ],
            loading: false,
            total: 0,
            show: {
                removeCashRegister: false,
                configurePayments: false,
                shiftDetails: false
            },
            selectedCashRegister: {
                latest_shift: {
                    closed_at: ""
                }
            },
            removeCashRegisterClicked: false,
            payment: {
                allPayments: { text: "Sva placanja", selected: false, value: 'all_payments' },
                basicPayments: { text: "Osnovna placanja", selected: false, value: 'limited_payments' }
            },
            select: '',
            selectedConfigurePayments: false,
        }
    },
    mounted() {
        this.loading = true;
        ApiService.get(`/admin/tenants/${this.$route.params.id}`).then(
            response => {
                this.tenant = response.data.tenant;
                this.loading = false;
                this.total = response.data.total;
                this.tenant.cashRegisters = response.data.cash_registers;
                this.loadingContainer = true;

                if (!this.tenant.cashRegisters)
                    if (!this.tenant.cashRegisters[0].settings)
                        this.headers = this.headers.filter(item => item.value !== 'payments')

                let exists = false;
                if (this.tenant.cashRegisters) {
                    this.tenant.cashRegisters.forEach(item => {
                        if (item.settings) exists = true;
                    })
                }

                if (!exists) this.headers = this.headers.filter(item => item.value !== 'payments')
            }
        );
    },
    computed: {
        canSee() {
            return this.$store.state.user.role === 'admin' || this.$store.state.user.role === 'main_reseller';
        }
    },
    methods: {
        openRemoveCashRegisterDialog(item) {
            this.selectedCashRegister = item;
            this.show.removeCashRegister = true;
        },
        removeCashRegister() {
            this.removeCashRegisterClicked = true;

            ApiService.delete(`/admin/tenants/${this.tenant.id}/cash-registers/${this.selectedCashRegister.id}`).then(
                response => {
                    if (response.status === 200) {
                        const index = this.tenant.cashRegisters.findIndex(item => item.id === this.selectedCashRegister.id);
                        this.tenant.cashRegisters = this.tenant.cashRegisters.filter(item => item.id !== this.tenant.cashRegisters[index].id)

                        this.show.removeCashRegister = false;
                        this.removeCashRegisterClicked = false;
                        this.$snackbar(this.$t(`tenant.${response.data.message}`), 'success');
                    }
                })
                .catch((error) => {
                    this.removeCashRegisterClicked = false;
                    this.$snackbar(this.$t(`tenant.${error.response.data.message}`), 'error');
                });
        },
        openConfigurePayments(item) {
            this.selectedCashRegister = item;
            this.determinePaymentForSelect();
            this.show.configurePayments = true;
        },
        configurePayments(selected) {
            const paymentSettings = selected === this.$t('cash_register.all_payments') ? 'all_payments' : 'limited_payments';

            this.selectedConfigurePayments = true;
            ApiService.patch(`/admin/tenants/${this.tenant.id}/cash-registers/${this.selectedCashRegister.id}`, { payment_settings: paymentSettings })
                .then(response => {
                    const index = this.tenant.cashRegisters.findIndex(item => item.id === this.selectedCashRegister.id);
                    this.tenant.cashRegisters[index].settings = response.data.cash_register.settings;

                    this.show.configurePayments = false;
                    this.selectedConfigurePayments = false;

                    this.select = 0;
                    this.$snackbar(this.$t(`cash_register.${response.data.message}`), 'success');

                });
        },
        determinePaymentForSelect() {
            let paymentsCounter = 0;

            if (this.selectedCashRegister.settings)
                for (let payment in this.selectedCashRegister.settings.payments) {
                    if (this.selectedCashRegister.settings.payments[payment])
                        paymentsCounter++;
                }

            if (paymentsCounter === 8)
                this.select = this.$t('cash_register.all_payments');
            else if (paymentsCounter === 5)
                this.select = this.$t('cash_register.limited_payments');
            else
                this.select = '';
        },
        showShiftDetails(item) {
            this.show.shiftDetails = true;
            this.selectedCashRegister = item;
        },

        closeShift(item) {

            ApiService.patch(`/admin/tenants/${this.$route.params.id}/shifts/${item.latest_shift.id}/close-shift`)
                .then(response => {

                    const index = this.tenant.cashRegisters.findIndex(cashReg => cashReg.id === item.id);

                    this.tenant.cashRegisters[index].latest_shift = response.data;

                    this.$snackbar(this.$t('general.shift_successfully_closed'), 'success');
                })
                .catch((error) => {
                    this.$snackbar(this.$t('general.closing_shift_error'), 'error');
                });
        }
    }
}
</script>

<style>
.payment-td {
    white-space: nowrap;
    font-size: smaller;
}
</style>
