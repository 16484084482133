import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notifications"},[_c(VRow,{staticClass:"mb-4"},[_c(VCol,{attrs:{"cols":"6","sm":"6"}},[_c(VTextField,{attrs:{"label":_vm.$t('general.searchByEmail'),"append-icon":"far fa-search","clearable":"","clear-icon":"mdi mdi-magnify","single-line":"","hide-details":""},on:{"input":function($event){return _vm.filterNotifications()}},model:{value:(_vm.email_filter),callback:function ($$v) {_vm.email_filter=$$v},expression:"email_filter"}})],1),_vm._v(" "),_c(VCol,{attrs:{"align":"right"}},[_c(VBtn,{attrs:{"outlined":""},on:{"click":function($event){_vm.show.createNotification = true;}}},[_c(VIcon,[_vm._v("mdi mdi-plus")]),_vm._v("\n                "+_vm._s(_vm.$t('general.create'))+"\n            ")],1)],1)],1),_vm._v(" "),_c(VDataTable,{staticClass:"operations striped",attrs:{"headers":_vm.headers,"items":_vm.notifications,"options":_vm.pagination,"server-items-length":_vm.total,"footer-props":{'items-per-page-options': [20, 50, 100, 200, 500, 1000]},"items-per-page":_vm.pagination.itemsPerPage,"loading":_vm.loading,"mobile-breakpoint":0},on:{"update:options":function($event){_vm.pagination=$event},"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.text != null ? item.text.substring(0,80) : item.text))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.tenant.email))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm._f("dateFromUtc")(item.created_at)))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm._f("dateFromUtc")(item.read_at)))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.code))]),_vm._v(" "),_c('td',{staticClass:"w60",attrs:{"align":"center"}},[_c(VMenu,{attrs:{"bottom":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi mdi-dots-horizontal")])],1)]}}],null,true)},[_vm._v(" "),_c(VList,[[_c(VListItem,{key:1,on:{"click":function($event){return _vm.showDetails(item)}}},[_c(VListItemAction,{staticClass:"mr-4 flex justify-content-center"},[_c(VIcon,[_vm._v("mdi mdi-eye-outline")])],1),_vm._v(" "),_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('general.details')))])],1)]],2)],1)],1)])]}}])}),_vm._v(" "),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.show.createNotification),callback:function ($$v) {_vm.$set(_vm.show, "createNotification", $$v)},expression:"show.createNotification"}},[_c('div',{staticClass:"dialog"},[_c('div',{staticClass:"dialog-header"},[_vm._v("\n                "+_vm._s(_vm.$t('notifications.create'))+"\n            ")]),_vm._v(" "),_c('NotificationForm',{on:{"save":_vm.onNotificationCreate}})],1)]),_vm._v(" "),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.show.details),callback:function ($$v) {_vm.$set(_vm.show, "details", $$v)},expression:"show.details"}},[_c('div',{staticClass:"dialog"},[_c('div',{staticClass:"dialog-header"},[_vm._v("\n                "+_vm._s(_vm.$t('general.details'))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"dialogText"},[_c('div',{staticClass:"ma-4"},[_vm._v("\n                   "+_vm._s(this.details.text)+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"ma-4"},[_vm._v("\n                   "+_vm._s(_vm.$t('notifications.created_at'))+" : "+_vm._s(_vm._f("dateFromUtc")(this.details.created_at))+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"ma-4"},[_vm._v("\n                   "+_vm._s(_vm.$t('notifications.read_at'))+" : "+_vm._s(_vm._f("dateFromUtc")(this.details.read_at))+"\n               ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }