<template>
  <v-dialog
    :value="value"
    width="500"
    @input="$emit('input', false)"
  >
    <div
      v-if="curTenant && curTenant.venue"
      class="edit-venue"
    >
      <div class="edit-venue-header">Venue: {{ curTenant.venue.name }}</div>

      <venue-form
        class="edit-venue-content"
        :tenant="curTenant"
        @save="save"
      />
    </div>
  </v-dialog>
</template>

<script>
import VenueForm from '@/views/admin/VenueForm';

export default {
  components: {
    VenueForm,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    curTenant: {
      type: Object,
      default: () => null,
    },
  },

  methods: {
    save() {
      this.$emit('success');
      this.$emit('input', false);
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>
.edit-venue {
  .edit-venue-header {
    padding: 16px;
    background: #363c4a;
    color: white;
  }

  .edit-venue-content {
    padding: 16px;
    background: white;
  }
}
</style>
