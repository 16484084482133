<template>
  <v-dialog
    :value="value"
    width="500"
    @input="$emit('input', false)"
  >
    <v-card
      v-if="value"
      flat
    >
      <v-card-title class="dialog-header">
        {{ $t('general.duplicate_user') }}
      </v-card-title>

      <v-card-text class="overflow-auto">
        <h3 class="mb-4">{{ $t('general.venue_info') }}</h3>

        <v-text-field
          v-model="duplicateTenantForm.venueName"
          :error-messages="duplicateTenantForm.$errors.venueName"
          :label="$t('general.venue_name')"
          @blur="duplicateTenantForm.validate('venueName')"
          @input="delete duplicateTenantForm.$errors.venueName"
        />

        <v-select
          v-model="duplicateTenantForm.country"
          :items="locales"
          :label="$t('general.country')"
          @blur="duplicateTenantForm.validate('country')"
          @input="delete duplicateTenantForm.$errors.country"
        />

        <v-text-field
          v-model="duplicateTenantForm.venueAddress"
          :error-messages="duplicateTenantForm.$errors.venueAddress"
          :label="$t('general.venue_address')"
          @blur="duplicateTenantForm.validate('venueAddress')"
          @input="delete duplicateTenantForm.$errors.venueAddress"
        />

        <h3 class="mb-4">{{ $t('general.company_info') }}</h3>

        <v-text-field
          v-model="duplicateTenantForm.taxIdNumber"
          :error-messages="duplicateTenantForm.$errors.taxIdNumber"
          :label="$t('general.tax_id_number')"
          :loading="loading"
          @blur="duplicateTenantForm.validate('taxIdNumber')"
          @input="handleTaxIdNumberInput"
        />

        <v-text-field
          v-model="duplicateTenantForm.companyName"
          :error-messages="duplicateTenantForm.$errors.companyName"
          :label="$t('general.company_name')"
          :loading="loading"
          @blur="duplicateTenantForm.validate('companyName')"
          @input="delete duplicateTenantForm.$errors.companyName"
        />

        <v-text-field
          v-model="duplicateTenantForm.companyAddress"
          :error-messages="duplicateTenantForm.$errors.companyAddress"
          :label="$t('general.company_address')"
          :loading="loading"
          @blur="duplicateTenantForm.validate('companyAddress')"
          @input="delete duplicateTenantForm.$errors.companyAddress"
        />

        <v-text-field
          v-model="duplicateTenantForm.city"
          :error-messages="duplicateTenantForm.$errors.city"
          :label="$t('general.city')"
          :loading="loading"
          @blur="duplicateTenantForm.validate('city')"
          @input="delete duplicateTenantForm.$errors.city"
        />

        <v-text-field
          v-model="duplicateTenantForm.registrationNumber"
          :error-messages="duplicateTenantForm.$errors.registrationNumber"
          :label="$t('general.registration_number')"
          :loading="loading"
          @blur="duplicateTenantForm.validate('registrationNumber')"
          @input="delete duplicateTenantForm.$errors.registrationNumber"
        />

        <v-text-field
          v-model="duplicateTenantForm.bankAccountNumber"
          :error-messages="duplicateTenantForm.$errors.bankAccountNumber"
          :label="$t('general.bank_account_number')"
          :loading="loading"
          @blur="duplicateTenantForm.validate('bankAccountNumber')"
          @input="delete duplicateTenantForm.$errors.bankAccountNumber"
        />

        <v-row>
          <v-col>
            <v-checkbox
              v-model="duplicateTenantForm.taxesEnabled"
              :label="$t('general.tax_payer')"
              :true-value="true"
              :false-value="false"
              :error-messages="duplicateTenantForm.$errors.taxesEnabled"
            />
          </v-col>

          <v-col>
            <v-checkbox
              v-model="duplicateTenantForm.reverseCalculation"
              :label="$t('general.reverse_calculation')"
              :true-value="true"
              :false-value="false"
              :error-messages="duplicateTenantForm.$errors.reverseCalculation"
            />
          </v-col>
        </v-row>

        <template v-if="curTenant.venue.country === 'RS' && duplicateTenantForm.envoiceApiKey">
          <h3 class="mb-4">{{ $t('modules.einvoices') }}</h3>

          <v-text-field
            v-model="duplicateTenantForm.envoiceApiKey"
            :error-messages="duplicateTenantForm.$errors.envoiceApiKey"
            :label="$t('general.einvoice_api_key')"
          />
        </template>

        <template v-if="curTenant.venue.country === 'ME'">
          <h3 class="mb-4"> {{ $t('general.certificate') }}</h3>

          <v-text-field
            v-model="duplicateTenantForm.cert_password"
            :error-messages="duplicateTenantForm.$errors.cert_password"
            :type="showCertPassword ? 'text' : 'password'"
            :label="$t('general.password')"
            :placeholder="'Password'"
            autocomplete="new-password"
            :append-icon="showCertPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @blur="duplicateTenantForm.validate('cert_password')"
            @click:append="showCertPassword = !showCertPassword"
            @input="delete duplicateTenantForm.$errors.cert_password"
          />

          <v-file-input
            :label="$t('general.certificate')"
            :placeholder="'Certificate'"
            :error-messages="duplicateTenantForm.$errors.cert"
            prepend-icon=""
            append-icon="mdi-paperclip"
            clearable
            validate-on-blur
            @blur="duplicateTenantForm.validate('cert')"
            @change="duplicateTenantForm.encodeFile('cert', $event)"
          />

          <v-text-field
            v-model="duplicateTenantForm.operator_code"
            :label="$t('general.operator_code')"
            :placeholder="$t('general.operator_code')"
            :hint="$t('general.operator_code_description')"
            :error-messages="duplicateTenantForm.$errors.operator_code"
            @blur="duplicateTenantForm.validate('operator_code')"
            @input="delete duplicateTenantForm.$errors.operator_code"
          />

          <v-text-field
            v-model="duplicateTenantForm.hash"
            :label="$t('general.venue_code')"
            :placeholder="$t('general.venue_code')"
            :error-messages="duplicateTenantForm.$errors.hash"
            @blur="duplicateTenantForm.validate('hash')"
            @input="delete duplicateTenantForm.$errors.hash"
          />
        </template>

        <h3 class="mb-4">{{ $t('general.basic_info') }}</h3>

        <v-text-field
          v-model="duplicateTenantForm.name"
          :error-messages="duplicateTenantForm.$errors.name"
          :label="$t('general.name')"
          @blur="duplicateTenantForm.validate('name')"
          @input="delete duplicateTenantForm.$errors.name"
        />

        <v-text-field
          v-model="duplicateTenantForm.email"
          :error-messages="duplicateTenantForm.$errors.email"
          :label="$t('general.email')"
          @blur="duplicateTenantForm.validate('email')"
          @input="delete duplicateTenantForm.$errors.email"
        />

        <v-text-field
          v-model="duplicateTenantForm.phone"
          :error-messages="duplicateTenantForm.$errors.phone"
          :label="$t('general.phone')"
          @blur="duplicateTenantForm.validate('phone')"
          @input="delete duplicateTenantForm.$errors.phone"
        />
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          outlined
          :loading="duplicateTenantForm.$busy || loading"
          @click="duplicateTenant()"
        >
          {{ $t('general.create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { debounce, mapKeys, snakeCase } from 'lodash';
import * as rules from 'vuelidate/lib/validators';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    curTenant: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      duplicateTenantForm: this.createDuplicateTenantForm(),
      showCertPassword: false,
      loading: false,
    };
  },

  validations: {
    duplicateTenantForm: {
      venueName: { required: rules.required },
      country: { required: rules.required },
      venueAddress: { required: rules.required },
      name: { required: rules.required },
      taxIdNumber: {
        required: rules.required,
        minLength: rules.minLength(process.env.APP_LOCALE === 'sr' ? 9 : 8),
        maxLength: rules.maxLength(13)
      },
      phone: { required: rules.required },
      email: { required: rules.required, email: rules.email },
      companyAddress: { required: rules.required },
      companyName: { required: rules.required },
      taxesEnabled: {},
      reverseCalculation: {},
      bankAccountNumber: {},
      registrationNumber: {},
      envoiceApiKey: {},
      city: {},
      cert: {
        requiredIf: rules.requiredIf(function () {
          return this.curTenant.venue.country === 'ME'
        })
      },
      cert_password: {
        requiredIf: rules.requiredIf(function () {
          return this.curTenant.venue.country === 'ME'
        })
      },
      hash: {
        requiredIf: rules.requiredIf(function () {
          return this.curTenant.venue.country === 'ME'
        })
      },
      operator_code: {
        requiredIf: rules.requiredIf(function () {
          return this.curTenant.venue.country === 'ME'
        })
      },
    },
  },

  watch: {
    value(val) {
      if (!val) {
        this.duplicateTenantForm = this.createDuplicateTenantForm();
      }
    },
  },

  computed: {
    taxIdNumberLength() {
      return process.env.APP_LOCALE === 'sr' ? 9 : 8;
    },
  },

  methods: {
    createDuplicateTenantForm(item = null) {
      return new CodForm(this, 'duplicateTenantForm', {
        venueName: item?.venue.name ? item.venue.name : null,
        country: item?.venue.country ? item.venue.country.toLowerCase() : null,
        venueAddress: item?.venue.address ? item.venue.address : null,
        registrationNumber: null,
        companyName: null,
        companyAddress: null,
        city: null,
        bankAccountNumber: null,
        name: item?.name ? item.name : null,
        taxIdNumber: item?.venue.tax_id_number ? item.venue.tax_id_number : null,
        phone: item?.phone ? item.phone : null,
        email: item?.email ? item.email : null,
        taxesEnabled: item?.venue.taxes_enabled ? true : false,
        reverseCalculation: item?.venue.reverse_calculation ? true : false,
        envoiceApiKey: item?.venue.settings.einvoice.api_key ? item.venue.settings.einvoice.api_key : null,
        cert: null,
        cert_password: null,
        hash: null,
        operator_code: null,
      })
    },

    async duplicateTenant() {
      if (!this.duplicateTenantForm.validate()) {
        return
      }

      const data = mapKeys(this.duplicateTenantForm, (_value, key) => snakeCase(key))

      delete data.errors
      delete data.v

      if (!data.envoiceApiKey) {
        delete data.envoiceApiKey
      }

      this.duplicateTenantForm.$busy = true

      try {
        await ApiService.post(`/admin/tenants/${this.curTenant.id}/duplicate`, data);
        this.duplicateTenantForm.$busy = false
        this.$snackbar('Tenant duplicated successfully', 'success');
        this.$emit('success');
        this.$emit('input', false);
      } catch (error) {
        this.duplicateTenantForm.$busy = false
        this.duplicateTenantForm.setErrors(error.response.data.errors);
        this.$snackbar('Failed to duplicate tenant', 'error');
      }
    },

    handleTaxIdNumberInput() {
      delete this.duplicateTenantForm.$errors.taxIdNumber;

      if (this.duplicateTenantForm.taxIdNumber.length !== this.taxIdNumberLength) {
        this.loading = false;
        return;
      }

      this.loading = true;

      debounce(() => {
        this.searchCompany();
      }, 500).call(this);
    },

    async searchCompany() {
      await ApiService.get('/admin/companies/search/' + this.duplicateTenantForm.taxIdNumber).then(response => {
        this.duplicateTenantForm.companyName = response.data.company.name;
        this.duplicateTenantForm.companyAddress = response.data.company.address;
        this.duplicateTenantForm.city = response.data.company.city;
        this.duplicateTenantForm.registrationNumber = response.data.company.registration_number;
        this.duplicateTenantForm.bankAccountNumber = response.data.company.bank_account_number;
        this.loading = false;
      }).catch(() => {
        this.$snackbar('Company not found', 'error');
        this.loading = false;
      });
    },
  },
}
</script>
