import * as types from "./mutationTypes";

export default {
    [types.SET_USER](state, data) {
        state.user = data.user;
    },

    [types.SET_APP_CONFIG](state, data) {
        state.appConfig = data.appConfig;
    },

    [types.SET_TAX_RATES](state, data) {
        state.taxRates = data.taxRates;
    }
};
