import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VAppBar,{staticClass:"admin-app-bar elevation-0",attrs:{"app":""}},[_c(VIcon,{on:{"click":function($event){$event.stopPropagation();return _vm.toggleDrawer.apply(null, arguments)}}},[_vm._v("mdi mdi-menu")]),_vm._v(" "),(_vm.pageTitle)?_c('div',{staticClass:"text-h5 ml-4"},[_vm._v(_vm._s(_vm.$t(_vm.pageTitle)))]):_vm._e(),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VMenu,{attrs:{"bottom":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VAvatar,{attrs:{"size":"44"}},[_c(VIcon,[_vm._v("mdi mdi-account-circle-outline")])],1)],1)]}}])},[_vm._v(" "),(_vm.user)?_c(VList,{staticClass:"user-menu"},[_c(VListItem,{staticClass:"label"},[_c(VListItemAvatar,[_c(VAvatar,{staticClass:"primary",attrs:{"size":"40"}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi mdi-account")])],1)],1),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.user.name))])],1)],1),_vm._v(" "),_c(VDivider),_vm._v(" "),_c(VListItem,{on:{"click":function($event){return _vm.changePass()}}},[_c(VListItemAction,[_c(VIcon,{staticClass:"logout-icon"},[_vm._v("mdi mdi-key")])],1),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('general.edit_user_password')))])],1)],1),_vm._v(" "),_c(VListItem,{on:{"click":_vm.logout}},[_c(VListItemAction,[_c(VIcon,{staticClass:"logout-icon"},[_vm._v("mdi mdi-logout-variant")])],1),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('general.logout')))])],1)],1)],1):_vm._e()],1)],1),_vm._v(" "),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.show.changePass),callback:function ($$v) {_vm.$set(_vm.show, "changePass", $$v)},expression:"show.changePass"}},[_c('div',{staticClass:"edit-venue"},[_c('div',{staticClass:"edit-venue-header"},[_vm._v("\n        "+_vm._s(_vm.$t('general.edit_user_password'))+"\n      ")]),_vm._v(" "),_c('change-password-form',{staticClass:"edit-venue-content",attrs:{"userData":_vm.$store.state.user,"dialog":_vm.show.changePass},on:{"save":_vm.onPasswordUpdate}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }