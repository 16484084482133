<template>
    <div class="tenants">
        <v-card-title>
            <v-text-field
                v-model="filter"
                class="search"
                :label="$t('customers.search')"
                append-icon="far fa-search"
                clearable
                clear-icon="mdi mdi-magnify"
                single-line
                hide-details
                @input="filterTenants()"
            />
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-select
                style="width: 50px;"
                v-model="selectedFilters"
                :items="filters"
                item-text="name"
                item-value="code"
                chips
                :label="$t('general.filters')"
                multiple
                @change="getTenants"
             >
                <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0 || index === 1">
                        <span>{{ item.name }}</span>
                    </v-chip>
                    <span
                        v-if="index === 2"
                        class="grey--text text-caption"
                    >
                      (+{{ selectedFilters.length - 2 }} {{$t('general.more')}})
                    </span>
                </template>
            </v-select>
        </v-card-title>
        <v-data-table
            class="operations striped"
            :headers="visibleHeaders"
            :items="tenants"
            :options.sync="pagination"
            :server-items-length="total"
            :footer-props="{'items-per-page-options': [50, 100, 200, 500]}"
            :items-per-page.sync="pagination.itemsPerPage"
            :loading="loading"
            :mobile-breakpoint="0"
        >
            <template v-slot:item="{ item, index }">
                <tr>
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.reseller ? item.reseller.name : '' }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.app }}</td>
                    <td>{{ item.venue ? item.venue.name : '' }}</td>
                    <td>{{ item.venue ? item.venue.country + ' ' + item.venue.address : '' }}</td>
                    <td v-if="isCountry('ME')">{{ item.cert_expire_at  | dateFromUtc }}</td>
                    <td>{{ item.created_at | dateFromUtc }}</td>
                    <td>{{ item.trial_ends_at | dateFromUtc }}</td>
                    <td class="w60" align="center">
                        <v-menu bottom transition="slide-y-transition">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon>
                                    <v-icon v-if="impersonatingTenantId === item.id">mdi mdi-account</v-icon>
                                    <v-icon v-else>mdi mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item
                                    v-if="item.venue"
                                    :key="1"
                                    @click="openTenantDetails(item)"
                                >
                                    <v-list-item-action class="mr-4 flex justify-content-center">
                                        <v-icon>mdi mdi-eye-outline</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-title>{{$t('general.details')}}</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="!item.venue && item.phone"
                                    :key="10"
                                    @click="openTenantPhone(item)"
                                >
                                    <v-list-item-action class="mr-4 flex justify-content-center">
                                        <v-icon>mdi mdi-phone</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-title>{{$t('general.phoneNumber')}}</v-list-item-title>
                                </v-list-item>
                                <v-divider />
                                <v-list-item
                                    v-if="item.venue"
                                    :key="2"
                                    @click="editVenue(item)"
                                >
                                    <v-list-item-action class="mr-4 flex justify-content-center">
                                        <v-icon>mdi mdi-account-edit</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-title>{{$t('general.edit_venue')}}</v-list-item-title>
                                </v-list-item>

                                <v-divider />

                                <v-list-item
                                    v-if="item.venue"
                                    :key="3"
                                    @click="editTenant(item)"
                                >
                                    <v-list-item-action class="mr-4 flex justify-content-center">
                                        <v-icon>mdi mdi-account-cog</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-title>{{$t('general.edit_tenant')}}</v-list-item-title>
                                </v-list-item>
                                <v-divider />

                                <template v-if="$store.state.user.role === 'admin'">
                                    <v-list-item
                                        :key="4"
                                        @click="impersonate(item.id, item.email)"
                                        v-if="$store.state.user.role === 'admin'"
                                    >
                                            <v-list-item-action class="mr-4 flex justify-content-center">
                                                <v-icon>mdi mdi-account-convert</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-title v-if="impersonatingTenantId === item.id">{{$t('general.stop_impersonate')}}</v-list-item-title>
                                            <v-list-item-title v-else="impersonatingTenantId === item.id">{{$t('general.impersonate')}}</v-list-item-title>
                                    </v-list-item>
                                    <v-divider />
                                </template>

                                <v-list-item
                                 v-if="isCountry('ME')"
                                 :key="5"
                                 @click="openCertificate(item)"
                                >
                                    <v-list-item-action class="mr-4 flex justify-content-center">
                                        <v-icon>mdi mdi-file-certificate</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-title>{{$t('general.certificate')}}</v-list-item-title>
                                </v-list-item>

                                <v-divider v-if="isCountry('ME')" />

                                <v-list-item
                                    :key="6"
                                    @click="onChangeCertificate(item)"
                                     v-if="($store.state.user.role === 'admin' || $store.state.user.role === 'main_reseller' || isGoranOrIvanReseller() ) &&  isCountry('ME')"

                                >
                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                            <v-icon>mdi mdi-file-upload</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-title>{{$t('general.change_certificate')}}</v-list-item-title>
                                </v-list-item>
                                <v-divider />
                                <v-list-item
                                    v-if="$store.state.user.role === 'admin'"
                                    :key="8"
                                    @click="showTenantLogs(item.id)"
                                >
                                    <v-list-item-action class="mr-4 flex justify-content-center">
                                        <v-icon>mdi mdi-information</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-title>
                                        {{$t('general.logs')}}
                                    </v-list-item-title>
                                    <v-divider />
                                </v-list-item>
                                <v-divider />


                                <v-divider/>

                                <v-list-item v-if="$store.state.user.role === 'admin' || $store.state.user.role === 'main_reseller' || $store.state.user.role === 'reseller'"
                                    :key="9"
                                    @click="onDeleteAllProducts(item)"
                                >
                                    <v-list-item-action class="mr-4 flex justify-content-center">
                                        <v-icon class="error--text">mdi mdi-delete-sweep</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-title class="error--text">{{$t('general.delete_products')}}</v-list-item-title>
                                </v-list-item>

                                <v-divider/>

                                <v-list-item
                                    :key="10"
                                    v-if="$store.state.user.role === 'admin' || $store.state.user.role === 'main_reseller'"
                                    @click="deleteVenueDialog(item)"
                                >
                                    <v-list-item-action class="mr-4 flex justify-content-center">
                                        <v-icon class="error--text">mdi mdi-delete-outline</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-title class="error--text">{{$t('general.delete')}}</v-list-item-title>
                                </v-list-item>

                            </v-list>
                        </v-menu>

                    </td>
                </tr>
            </template>
        </v-data-table>

        <v-dialog
            v-model="show.editVenue"
            width="500"
        >
            <div v-if="curTenant && curTenant.venue" class="edit-venue">
                <div class="edit-venue-header">Venue: {{ curTenant.venue.name }}</div>

                <venue-form
                    class="edit-venue-content"
                    :tenant="curTenant"
                    @save="onVenueUpdate"
                />
            </div>
        </v-dialog>
        <v-dialog
            v-model="show.deleteVenue"
            width="500"
        >
            <div v-if="curTenant" class="edit-venue">
                <div class="edit-venue-header">
                    {{$t('customers.action_delete_message')}}
                </div>
                <venue-delete
                    class="edit-venue-content"
                    :tenant="curTenant"
                    @delete="onTenantDelete"
                />
            </div>
        </v-dialog>

        <v-dialog
            v-model="show.deleteAllProducts"
            width="400"
        >
            <div v-if="curTenant" class="edit-venue">
                <div class="edit-venue-header">
                     {{$t('general.delete_products')}}: <b>{{curTenant.email}}</b>
                </div>
                <v-card style="text-align:center; padding: 10px;" >
                    <v-btn
                        outlined
                        class="error--text"
                        @click="deleteAllProducts"
                    ><v-icon> mdi-delete-sweep</v-icon> Potvrdi
                    </v-btn>
                    <v-btn
                        outlined
                        @click="show.deleteAllProducts = false"
                    ><v-icon>mdi-close</v-icon>Odbaci
                    </v-btn>
                </v-card>

            </div>
        </v-dialog>

        <v-dialog
            v-model="show.editTenant"
            width="500"
        >
            <div v-if="curTenant" class="edit-venue">
                <div class="edit-venue-header">
                    {{$t('general.edit_tenant')}}
                </div>
                <tenant-form
                    class="edit-venue-content"
                    :tenant="curTenant"
                    @save="onTenantUpdate"
                />
            </div>
        </v-dialog>

        <v-dialog
            v-model="show.uploadCertificate"
            width="500"
            :key="componentKey"
        >
        <div v-click-outside="resetForm">
            <div class="dialog-header">
                {{$t('general.upload_certificate')}}
            </div>
            <v-card style="padding: 2%">
                <v-col>
                    <v-text-field
                        v-model="form.cert_password"
                        :error-messages="form.$errors.cert_password"
                        :type="showCertPassword ? 'text' : 'password'"
                        :label="$t('general.password')"
                        :placeholder="'Password'"
                        outlined
                        autocomplete="new-password"
                        :append-icon="showCertPassword ? 'fal fa-eye' : 'fal fa-eye-slash'"
                        @click:append="showCertPassword = !showCertPassword"
                    />
                </v-col>
                <v-col>
                    <v-file-input
                        :label="$t('general.certificate')"
                        :placeholder="'Certificate'"
                        :error-messages="form.$errors.cert"
                        prepend-icon=""
                        append-icon="fal fa-key"
                        clearable
                        clear-icon="fal fa-times"
                        outlined
                        validate-on-blur
                        @change="form.encodeFile('cert', $event)"
                    />
                </v-col>
                <div style="text-align:center">
                    <v-btn
                        outlined
                        @click="uploadCertificate()"
                    >{{$t('general.save')}}
                    </v-btn>
                </div>
            </v-card>

        </div>
        </v-dialog>
        <v-dialog
            width="900"
            v-model="show.logs"
        >
            <div class="dialog-header">
                {{$t('general.logs')}}
            </div>
            <div class="dialog-body overflow-auto" >
                <Activity :logs="logs" />
            </div>
        </v-dialog>

        <!-- Phone number dialog -->

        <v-dialog
            width="400"
            v-model="show.phoneNumber"
        >
            <div class="dialog-header">
                {{$t('general.phoneNumber')}}
            </div>
            <div class="dialog-body overflow-auto" >
                <h3>{{ tenantPhoneNumber }} </h3>
            </div>
        </v-dialog>


        <v-dialog
            width="400"
            v-model="show.certificate"
        >
            <div class="dialog-header">
                {{$t('general.certificate')}}
            </div>
            <div class="dialog-body overflow-auto" >
                <vue-json-pretty  :data="certificate" />
            </div>
        </v-dialog>


    </div>
</template>

<script>
import _ from 'lodash'
import VenueForm from '@/views/admin/VenueForm';
import VenueDelete from '@/views/admin/VenueDelete';
import TenantForm from '@/views/admin/Tenants/TenantForm';
import * as rules from 'vuelidate/lib/validators'
import Activity from "../Activity/Activity.vue";
import VueJsonPretty from "vue-json-pretty";

export default {
    components: {
        VueJsonPretty,
        Activity,
        VenueForm,
        VenueDelete,
        TenantForm
    },
    created(){
        this.form = this.createForm()
        this.getImpersonatedCustomerId()
    },
    data() {
        return {
            loading: false,
            total: 0,
            pagination: {
                sortBy: ['created_at'],
                itemsPerPage: 200,
                page: 1,
                sortDesc: [true],
            },
            filters: [
                {name: this.$t('tenant.withoutVenueFilter') , code: "withoutVenueFilter"},
                {name: this.$t('tenant.withoutResellerFilter') , code: "withoutResellerFilter"},
                {name: this.$t('tenant.expiredContractsFilter') , code: "expiredContractsFilter"},
                {name: this.$t('tenant.withoutActiveContractsFilter') , code: "withoutActiveContractsFilter"},
                {name: this.$t('tenant.newTenantWithoutContractsFilter') , code: "newTenantWithoutContractsFilter"},
            ],
            selectedFilters : [],
            headers: [
                { text: this.$t('general.number'), sortable: false },
                { text: this.$t('general.name'), value: 'name' },
                { text: this.$t('general.reseller'), value: 'reseller.name' },
                { text: this.$t('general.email'), value: 'email' },
                { text: this.$t('general.app'), value: 'app' },
                { text: this.$t('general.venue'), value: 'venue.name' },
                { text: this.$t('general.adress'), value: 'venue.address' },
                {
                    text: this.$t('general.cert_expire_at'),
                    value: 'cert_expire_at' ,
                    visible: this.isCountry("ME")
                },
                { text: this.$t('general.created_at'),  value: 'created_at' },
                { text: this.$t('general.trial_ends_at'), value: 'trial_ends_at' },
                { text: '', sortable: false }
            ],

            filter: null,

            tenants: [],

            impersonatingTenantId: null,

            curTenant: null,
            show: {
                editVenue: false,
                deleteVenue: false,
                editTenant: false,
                uploadCertificate: false,
                deleteAllProducts: false,
                logs: false,
                phoneNumber: false,
                certificate: false
            },
            form: null,
            showCertPassword: false,
            componentKey: 0,
            logs:[],
            tenantPhoneNumber: null,
            certificate: {},
        };
    },
    validations: {
        form: {
            cert: { required: rules.required },
            cert_password: { required: rules.required },
        }
    },
    watch: {
        pagination: {
            deep: true,
            handler() {
                this.getTenants();
            },
        },
    },
    computed: {
        visibleHeaders() {
            return this.headers.filter(header => header.visible !== false);
        }
    },
    methods: {

        createForm() {
            return new CodForm(this, 'form', {
                cert: null,
                cert_password: null,
            })
        },
        /**
         * Get Paginated, Filtered and Sorted tenants from server
         */
        getTenants() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

            const params = {
                page: page,
                itemsPerPage: itemsPerPage,
                sortBy: sortBy[0] ? sortBy[0] : 'created_at',
                sortOrder: sortDesc[0] ? 'desc' : 'asc',
            }

            if(this.filter) params['textFilter'] = this.filter

            // Apply search filters directly to the params object
            this.selectedFilters.forEach((filter) => params[filter] = true);

            this.loading = true;
            ApiService.get('/admin/tenants', { params: params }).then(response => {
                this.loading = false;
                this.tenants = response.data.data;
                this.total = response.data.total;
            });
        },
        async impersonate(id, email){

            try {

                if(id === this.impersonatingTenantId){

                    await ApiService.get("/admin/tenants/stop")
                    this.$snackbar(`Impersonating of the customer ${email} stopped`, 'success');
                    return;

                }

                const response = await ApiService.post("/admin/tenants/impersonate", {tenant_id: id})

                if(response.status === 200){
                    this.$snackbar(`Impersonating of the customer ${email} is now active`, 'success');
                } else {
                    this.$snackbar(`Impersonating of the customer ${email} failed`, 'error');
                }

            } catch(error) {
                console.log(error);
                this.$snackbar(`Impersonating of the customer ${email} failed`, 'error');
            } finally {
                this.getImpersonatedCustomerId();
            }

        },
        async getImpersonatedCustomerId(){

            try {
                const response = await ApiService.get("/me");
                this.impersonatingTenantId = response.data.impersonating_tenant_id;
            }
            catch(error){
                console.log(error);
                this.$snackbar('Impersonating of the customer {{email}} failed', 'error');
            }

        },
        uploadCertificate() {

            if (!this.form.validate()) {
                return
            }
            ApiService.patch(`/admin/tenants/${this.curTenant.id}/update-certificate`, {
                cert: this.form.cert,
                cert_password: this.form.cert_password
            }).then(response => {
                this.resetForm()
                this.$snackbar('Certificate updated', 'success');
            }).catch(()=> {
                this.$snackbar('Changing certificate failed', 'error');
            });

        },
        async deleteAllProducts(){
            try {
                const response = await ApiService.delete(`/admin/tenants/${this.curTenant.id}/products`);
                if(response.status === 200){
                    this.$snackbar('All products successfully removed.', 'success');
                    this.show.deleteAllProducts = false;
                }
            }
            catch(error){
                console.log(error);
                this.$snackbar('Deleting products error.', 'error');
            }

        },
        onDeleteAllProducts(item){
            this.curTenant = item
            this.show.deleteAllProducts = true
        },
        /**
         * Open Edit Venue Dialog
         */
        editVenue(item) {
            this.curTenant = item
            this.show.editVenue = true
        },
        editTenant(item) {
            this.curTenant = item
            this.show.editTenant = true
        },
        deleteVenueDialog(item){
            this.curTenant = item
            this.show.deleteVenue = true;
        },
        /**
         * On tenant update replace the current tenant in the array with the updated one
         */
        onTenantUpdate(){
            this.show.editTenant = false
            this.curTenant = null
            this.getTenants();
        },
        onVenueUpdate() {
            this.show.editVenue = false
            this.curTenant = null
            this.getTenants();

        },
        onChangeCertificate(tenant){
            this.show.uploadCertificate = true;
            this.curTenant = tenant;
        },

        /**
         * Delete Tenant
        */
        onTenantDelete(){
            this.show.deleteVenue = false;
            this.curTenant = null;
            this.filterTenants();
        },
        /**
         * Filter Tenants
         */
        filterTenants: _.debounce(function() {
            this.getTenants();
        }, 500),
        resetForm(){
            this.componentKey++;
            this.form.cert_password = null;
            this.show.uploadCertificate = false;
        },
        openTenantDetails(item){
            this.$router.push({ name: "tenantDetails", params:{ id: item.id } })
        },
        openTenantPhone(item){
            this.show.phoneNumber = true
            this.tenantPhoneNumber = item.phone
        },
        async openCertificate(tenant){
            try {
                const response = await ApiService.get(`/admin/tenants/${tenant.id}/certificate`);
                const cert = response.data.cert
                this.certificate = {
                    subject: cert.subject,
                    hash: cert.hash,
                    issuer: cert.issuer,
                    validFrom: this.parseGeneralizedTime(cert.validFrom),
                    validTo:  this.parseGeneralizedTime(cert.validTo),
                }
                this.show.certificate = true
            }catch (error) {
                this.$snackbar(this.$t('general.certificate_error'), 'error');
            }

        },
        async showTenantLogs(id){
            const response = await ApiService.get(`/admin/tenants/${id}/all-activity`);
            this.logs = response.data.activities
            this.show.logs = true
        },

        isCountry(country){
            const reseller = this.$store.state.user.reseller
            if(reseller){
                return reseller.country === country
            }
            return true
        },
        isGoranOrIvanReseller() {
            //If user dont have reseller return false
            if (!this.$store.state.user.reseller) return false
            //By their reseller hash we check if user is Goran or Ivan
            return this.$store.state.user.reseller.hash === 'lm360rh852' || this.$store.state.user.reseller.hash === 'oa146vo061'
        },

        parseGeneralizedTime(generalizedTime) {
            let year = parseInt(generalizedTime.substring(0, 2), 10) + 2000;
            let month = parseInt(generalizedTime.substring(2, 4), 10) - 1;
            let day = parseInt(generalizedTime.substring(4, 6), 10);
            let hour = parseInt(generalizedTime.substring(6, 8), 10);
            let minute = parseInt(generalizedTime.substring(8, 10), 10);
            let second = parseInt(generalizedTime.substring(10, 12), 10);

            let formattedDate = year + "-" +
                String(month + 1).padStart(2, '0') + "-" +
                String(day).padStart(2, '0') + " " +
                String(hour).padStart(2, '0') + ":" +
                String(minute).padStart(2, '0') + ":" +
                String(second).padStart(2, '0');

            return formattedDate;
        },
    }
};
</script>

<style lang="scss" scoped>
.tenants {
    padding: 20px;
    background: white;
    border-radius: 4px;
    border: 1px solid #d3d5dc;

    .search {
        max-width: 400px;
        margin-bottom: 16px;
    }
}

.edit-venue {
    .edit-venue-header {
        padding: 16px;
        background: #363c4a;
        color: white;
    }

    .edit-venue-content {
        padding: 16px;
        background: white;
    }
}
</style>
