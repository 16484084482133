<template>
  <v-dialog
    v-if="value"
    :value="value"
    width="900"
    @input="$emit('input', false)"
  >
    <v-card>
      <v-card-title class="dialog-header">
        {{ $t('general.logs') }}
      </v-card-title>

      <v-card-text class="overflow-auto">
        <Activity :logs="logs" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Activity from '@/views/admin/Activity/Activity.vue'

export default {
  components: {
    Activity
  },

  props: {
    value: {
      type: Boolean,
      required: false
    },
    curTenant: {
      type: Object,
      default: () => { },
    },
  },

  data() {
    return {
      logs: []
    }
  },

  async created() {
    const response = await ApiService.get(`/admin/tenants/${this.curTenant.id}/all-activity`);

    this.logs = response.data.activities
  }
}
</script>
