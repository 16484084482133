import { locales } from "moment";

export default {
    user(state) {
        return state.user;
    },
    appConfig(state) {
        return state.appConfig;
    },
    locales(state) {
        return state.locales;
    },
    taxRates(state) {
        return state.taxRates;
    }
};
