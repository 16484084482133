import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value)?_c(VDialog,{attrs:{"value":_vm.value,"width":"500"},on:{"input":function($event){return _vm.$emit('input', false)}}},[_c(VCard,[_c(VCardTitle,{staticClass:"dialog-header"},[_vm._v("\n      "+_vm._s(_vm.$t('general.change_einvoice_api_key'))+"\n    ")]),_vm._v(" "),_c(VCardText,[_c(VTextField,{attrs:{"value":_vm.curTenant.venue.settings.einvoice.api_key,"readonly":"","prepend-icon":"mdi mdi-key-variant","label":_vm.$t('general.current_einvoice_api_key'),"error-messages":_vm.curTenant.venue.settings.einvoice.api_key ? null : 'This field is required',"hint":"This field is readonly"}}),_vm._v(" "),_c(VTextField,{attrs:{"prepend-icon":"mdi mdi-key-variant","label":_vm.$t('general.new_einvoice_api_key'),"error-messages":_vm.eInvoiceApiKeyForm.$errors.api_key},on:{"blur":function($event){return _vm.eInvoiceApiKeyForm.validate('api_key')},"input":function($event){delete _vm.eInvoiceApiKeyForm.$errors.api_key}},model:{value:(_vm.eInvoiceApiKeyForm.api_key),callback:function ($$v) {_vm.$set(_vm.eInvoiceApiKeyForm, "api_key", $$v)},expression:"eInvoiceApiKeyForm.api_key"}})],1),_vm._v(" "),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v("\n        "+_vm._s(_vm.$t('general.cancelBtn'))+"\n      ")]),_vm._v(" "),_c(VBtn,{attrs:{"outlined":""},on:{"click":function($event){return _vm.save()}}},[_vm._v("\n        "+_vm._s(_vm.$t('general.save'))+"\n      ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }