<template>
  <v-dialog
    v-if="value"
    :value="value"
    width="500"
    @input="$emit('input', false)"
  >
    <v-card>
      <v-card-title class="dialog-header">
        {{ $t('general.certificate') }}
      </v-card-title>

      <v-card-text class="overflow-auto">
        <vue-json-pretty :data="certificate" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";

export default {
  components: {
    VueJsonPretty
  },

  props: {
    value: {
      type: Boolean,
      required: false
    },
    curTenant: {
      type: Object,
      default: () => { },
    },
  },

  mounted() {
    this.openCertificate()
  },

  data: () => ({
    certificate: null,
  }),

  methods: {
    async openCertificate() {
      await ApiService.get(`/admin/tenants/${this.curTenant.id}/certificate`).then((response) => {
        const res = response.data.cert

        this.certificate = {
          subject: res.subject,
          hash: res.hash,
          issuer: res.issuer,
          validFrom: this.parseGeneralizedTime(res.validFrom),
          validTo: this.parseGeneralizedTime(res.validTo),
        }
      }).catch((error) => {
        console.log(error);
        this.$snackbar(this.$t('general.certificate_error'), 'error');
      })
    },

    parseGeneralizedTime(generalizedTime) {
      let year = parseInt(generalizedTime.substring(0, 2), 10) + 2000;
      let month = parseInt(generalizedTime.substring(2, 4), 10) - 1;
      let day = parseInt(generalizedTime.substring(4, 6), 10);
      let hour = parseInt(generalizedTime.substring(6, 8), 10);
      let minute = parseInt(generalizedTime.substring(8, 10), 10);
      let second = parseInt(generalizedTime.substring(10, 12), 10);

      let formattedDate = year + "-" +
        String(month + 1).padStart(2, '0') + "-" +
        String(day).padStart(2, '0') + " " +
        String(hour).padStart(2, '0') + ":" +
        String(minute).padStart(2, '0') + ":" +
        String(second).padStart(2, '0');

      return formattedDate;
    },
  }
}
</script>
