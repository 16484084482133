import { render, staticRenderFns } from "./CompanyDetails.vue?vue&type=template&id=76b3049e&"
import script from "./CompanyDetails.vue?vue&type=script&lang=js&"
export * from "./CompanyDetails.vue?vue&type=script&lang=js&"
import style0 from "./CompanyDetails.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports