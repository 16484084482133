<template>
  <!-- Clear stocks dialog-->
  <v-dialog
    :value="value"
    width="500"
    @input="$emit('input', false)"
  >
    <v-card>
      <v-card-title class="dialog-header">
        {{ $t('general.clear_stocks') }}
      </v-card-title>

      <v-card-text class="text-center flex-column mt-4">
        <div class="text-h6 error--text">
          <div class="mb-4">
            {{ $t('general.clear_stocks_description') }}
          </div>

          <div>
            {{ $t('general.clear_stocks_warning') }}
          </div>
        </div>
      </v-card-text>

      <v-card-actions class="d-flex justify-center pb-4">
        <v-btn
          text
          @click="$emit('input', false)"
        >
          {{ $t('general.cancelBtn') }}
        </v-btn>

        <v-btn
          outlined
          depressed
          class="error--text"
          @click="clearStocks"
        >
          {{ $t('general.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    curTenant: {
      type: Object,
      default: () => null,
    },
  },

  methods: {
    /**
     * Clear all stocks
     */
    clearStocks() {
      ApiService.patch(`/admin/tenants/${this.curTenant.id}/products/zero-count`).then(() => {
        this.$snackbar('Stocks cleared', 'success');
        this.$emit('input', false);
        this.$emit('success');
      }).catch(() => {
        this.$snackbar('Clearing stocks failed', 'error');
      });
    },
  },
};
</script>
