import { camelCase, mapKeys } from "lodash";

export default class CodForm {
    constructor(component, formName, data) {
        this.$busy = false;
        this.$errors = {};
        component.$nextTick(() => {
            this.$v = component.$v ? component.$v[formName] : null;
        });

        Object.assign(this, data);
    }

    /**
     * Start processing the form
     */
    startProcessing() {
        this.$busy = true;
        this.$errors = {};
    }

    /**
     * Finish processing the form
     */
    finishProcessing() {
        this.$busy = false;
    }

    /**
     * Reset the errors and other state for the form
     */
    reset() {
        this.$busy = false;
        this.$errors = {};

        if (this.$v) {
            this.$v.$reset();
        }
    }

    /**
     * Validate the whole form or the specific field
     */
    validate(field = null) {
        if (!this.$v) {
            this.$errors = {};
            return true;
        }

        if (!field) {
            let errors = {};
            this.$v.$touch();

            // Get all the fields to be validated
            const validatedFields = this.$v
                .$flattenParams()
                .map(param => param.path.join(".").replace(".$each", ""))
                .filter(
                    (field, index, array) => array.indexOf(field) === index
                );

            validatedFields.forEach(fieldName => {
                errors[fieldName] = this.getFieldErrors(fieldName);
            });

            this.$errors = errors;
            return this.isValid();
        }

        this.$errors = { ...this.$errors, [field]: this.getFieldErrors(field) };
        return this.isValid(field);
    }

    /**
     * Validate and return errors for the specific field
     */
    getFieldErrors(field) {
        if (!this.$v) {
            return this.$errors[field];
        }

        const [fieldName, index, key] = field.split(".");

        let errors = [];

        const validation = !index
            ? this.$v[fieldName]
            : this.$v[fieldName].$each[index][key];

        const rules = validation.$params;

        validation.$touch();

        Object.values(rules).forEach(rule => {
            if (rule && !validation[rule.type]) {
                let error = CodForm.errorMessage(rule);
                errors.push(error);
            }
        });

        return errors;
    }

    /**
     * Check if the whole form or the specific field is valid
     */
    isValid(field = null) {
        if (field) {
            return !this.$errors[field] || !this.$errors[field].length;
        }

        let valid = true;
        Object.keys(this.$errors).forEach(field => {
            if (this.$errors[field] && this.$errors[field].length) {
                valid = false;
            }
        });

        return valid;
    }

    /**
     * Set the errors on the form
     */
    setErrors(errors) {
        this.$errors = mapKeys(errors || {}, (value, key) => camelCase(key));
    }

    /**
     * Generate form data to send
     */
    toJson() {
        let jsonData = JSON.parse(
            JSON.stringify(this, (key, value) => {
                if (!["$errors", "$busy", "$v"].includes(key)) {
                    return value === undefined ? null : value;
                }
            })
        );

        return jsonData;
    }

    /**
     * Helper method for making POST HTTP requests.
     */
    post(uri, config = null) {
        return this.send("post", uri, config);
    }

    /**
     * Helper method for making PUT HTTP requests.
     */
    put(uri, config = null) {
        return this.send("put", uri, config);
    }

    /**
     * Helper method for making PATCH HTTP requests.
     */
    patch(uri, config = null) {
        return this.send("patch", uri, config);
    }

    /**
     * Helper method for making DELETE HTTP requests.
     */
    delete(uri, config = null) {
        return this.send("delete", uri, config);
    }

    /**
     * Send the form to the back-end server.
     */
    send(method, uri, config = null) {
        return new Promise((resolve, reject) => {
            this.startProcessing();

            axios[method](uri, this.toJson(), config)
                .then(response => {
                    this.finishProcessing();
                    resolve(response);
                })
                .catch(errors => {
                    this.finishProcessing();
                    this.setErrors(errors.response.data.errors);
                    reject(errors);
                });
        });
    }

    /**
     * Get validation messages
     */
    static errorMessage(rule) {
        switch (rule.type) {
            case "required":
                return "This field is required.";
            case "requiredIf":
                return "This field is required.";
            case "requiredUnless":
                return "This field is required.";
            case "minLength":
                return `Value must be at least ${rule.min} characters.`;
            case "maxLength":
                return `Value may not be greater than ${rule.max} characters.`;
            case "minValue":
                return `Value must be at least ${rule.min}`;
            case "maxValue":
                return `Value may not be greater than ${rule.max}`;
            case "between":
                return `Value must be between ${rule.min} and ${rule.max}.`;
            case "alpha":
                return "Value may only contain letters.";
            case "alphaNum":
                return "Value may only contain letters and numbers.";
            case "numeric":
                return "Value must be a number.";
            case "integer":
                return "Value must be an integer.";
            case "decimal":
                return "Value must be a decimal number.";
            case "email":
                return "E-mail address is not valid.";
            case "ipAddress":
                return "IP address is not valid.";
            case "macAddress":
                return "MAC address is not valid.";
            case "sameAs":
                return `Fields don't match.`;
            case "url":
                return "URL is not valid.";
            default:
                return "Field value is not valid.";
        }
    }

    encodeFile(field, file) {
        if (!file) {
            this[field] = null;
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this[field] = reader.result;
        };
    }
}
