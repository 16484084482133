<template>
  <v-dialog
    v-if="value"
    :value="value"
    width="500"
    @input="$emit('input', false)"
  >
    <v-card v-if="value">
      <v-card-title class="dialog-header">
        {{ $t('general.upload_certificate') }}
      </v-card-title>

      <v-card-text class="mt-4">
        <v-text-field
          v-model="form.cert_password"
          autocomplete="new-password"
          placeholder="Password"
          :type="showCertPassword ? 'text' : 'password'"
          :label="$t('general.password')"
          :append-icon="showCertPassword ? 'mdi-eye-off' : 'mdi-eye'"
          :error-messages="form.$errors.cert_password"
          @blur="form.validate('cert_password')"
          @input="delete form.$errors.cert_password"
          @click:append="showCertPassword = !showCertPassword"
        />

        <v-file-input
          clearable
          prepend-icon=""
          append-icon="mdi-paperclip"
          :label="$t('general.certificate')"
          :placeholder="'Certificate'"
          :error-messages="form.$errors.cert"
          @blur="form.validate('cert')"
          @change="form.encodeFile('cert', $event)"
        />
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          outlined
          @click="uploadCertificate()"
        >
          {{ $t('general.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as rules from 'vuelidate/lib/validators';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    curTenant: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      form: this.createForm(),
      showCertPassword: false
    }
  },

  validations: {
    form: {
      cert: { required: rules.required },
      cert_password: { required: rules.required },
    },
  },

  watch: {
    value(val) {
      if (!val) {
        this.form = this.createForm();
      }
    },
  },

  methods: {
    createForm() {
      return new CodForm(this, 'form', {
        cert: null,
        cert_password: null,
      })
    },

    uploadCertificate() {
      if (!this.form.validate()) {
        return
      }
      ApiService.patch(`/admin/tenants/${this.curTenant.id}/update-certificate`, {
        cert: this.form.cert,
        cert_password: this.form.cert_password
      }).then(() => {
        this.$snackbar('Certificate updated', 'success');
        this.$emit('update', false)
        this.$emit('success')
      }).catch((error) => {
        this.form.setErrors(error.response.data.errors);
        this.$snackbar('Changing certificate failed', 'error');
      });

    },
  }
}
</script>
