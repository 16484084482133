<template>
    <v-form
        v-if="form"
        ref="form"
        class="venue-form"
    >
        <v-text-field
            v-model="form.name"
            :error-messages="form.$errors.name"
            :label="$t('general.company_name')"
            @blur="form.validate('name')"
        />
        <v-text-field
            v-model="form.tax_id_number"
            :error-messages="form.$errors.tax_id_number"
            :label="$t('general.tax_id_number')"
            @blur="form.validate('tax_id_number')"
            @input="handleTaxIdNumberInput"
        />

        <v-text-field
            v-model="form.address"
            :error-messages="form.$errors.address"
            :label="$t('general.company_address')"
            @blur="form.validate('address')"
        />

        <v-text-field
            v-model="form.city"
            :error-messages="form.$errors.city"
            :label="$t('general.city')"
            @blur="form.validate('city')"
        />

        <v-text-field
            v-model="form.registration_number"
            :error-messages="form.$errors.registration_number"
            :label="$t('general.registration_number')"
            @blur="form.validate('registration_number')"
        />

        <v-text-field
            v-model="form.bank_account_number"
            :error-messages="form.$errors.bank_account_number"
            :label="$t('general.bank_account_number')"
            @blur="form.validate('bank_account_number')"
        />

        <div class="d-flex justify-center mb-3">
            <v-btn
                color="primary"
                outlined
                :loading="loading || form.$busy"
                @click="save"
            >
                Save
            </v-btn>
        </div>

    </v-form>
</template>

<script>
import ApiService from "@/services/ApiService";
import * as rules from "vuelidate/lib/validators";
import { debounce } from "lodash";

export default {
    props: {
        company: {
            type: Object,
            default: () => null,
        },
    },

    created() {
        this.form = this.createForm(this.company);
    },

    data() {
        return {
            form: null,
            loading: false,
        };
    },

    validations: {
        form: {
            name: { required: rules.required },
            tax_id_number: {
                required: rules.required,
                minLength: rules.minLength(process.env.APP_LOCALE === 'sr' ? 9 : 8),
                maxLength: rules.maxLength(13)
            },
            address: {},
            city: {},
            registration_number: {},
            bank_account_number: {},
        },
    },

    computed: {
        taxIdNumberLength() {
            return process.env.APP_LOCALE === 'sr' ? 9 : 8;
        }
    },

    methods: {
        createForm(company = null) {
            return new CodForm(this, "form", {
                id: company ? company.id : null,
                name: company ? company.name : null,
                tax_id_number: company ? company.tax_id_number : null,
                address: company ? company.address : null,
                city: company ? company.city : null,
                registration_number: company ? company.registration_number : null,
                bank_account_number: company ? company.bank_account_number : null,
            });
        },

        /**
         * Save Venue
         */
        save() {
            this.form.$busy = true;

            if (!this.form.validate()) return;

            ApiService.patch(`/admin/companies/${this.form.id}`, this.form)
                .then((response) => {
                    this.form.$busy = false;
                    this.$emit("save", response.data);
                    this.$snackbar("Company updated", "success");
                }
                ).catch(error => {
                    this.form.$busy = false;

                    if (error.response.status === 403) {
                        this.$snackbar('You are not authorized to perform this action.', 'error');
                    }
                }
                );
        },
        handleTaxIdNumberInput() {
            delete this.form.$errors.tax_id_number;

            if (this.form.tax_id_number.length !== this.taxIdNumberLength) {
                this.loading = false;
                return;
            }

            this.loading = true;

            debounce(() => {
                this.searchCompany();
            }, 500).call(this);
        },

        async searchCompany() {
            await ApiService.get('/admin/companies/search/' + this.form.tax_id_number).then(response => {
                this.form.name = response.data.company.name;
                this.form.address = response.data.company.address;
                this.form.city = response.data.company.city;
                this.form.registration_number = response.data.company.registration_number;
                this.form.bank_account_number = response.data.company.bank_account_number;
                this.loading = false;
            }).catch(() => {
                this.$snackbar('Company not found', 'error');
                this.loading = false;
            });
        },
    },
};
</script>
