<template>
  <v-dialog
    v-if="value"
    :value="value"
    width="500"
    @input="$emit('input', false)"
  >
    <v-card>
      <v-card-title class="dialog-header">
        {{ $t('general.change_einvoice_api_key') }}
      </v-card-title>

      <v-card-text>
        <v-text-field
          :value="curTenant.venue.settings.einvoice.api_key"
          readonly
          prepend-icon="mdi mdi-key-variant"
          :label="$t('general.current_einvoice_api_key')"
          :error-messages="curTenant.venue.settings.einvoice.api_key ? null : 'This field is required'"
          hint="This field is readonly"
        />

        <v-text-field
          v-model="eInvoiceApiKeyForm.api_key"
          prepend-icon="mdi mdi-key-variant"
          :label="$t('general.new_einvoice_api_key')"
          :error-messages="eInvoiceApiKeyForm.$errors.api_key"
          @blur="eInvoiceApiKeyForm.validate('api_key')"
          @input="delete eInvoiceApiKeyForm.$errors.api_key"
        />
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          text
          @click="$emit('input', false)"
        >
          {{ $t('general.cancelBtn') }}
        </v-btn>

        <v-btn
          outlined
          @click="save()"
        >
          {{ $t('general.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as rules from 'vuelidate/lib/validators'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    curTenant: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      eInvoiceApiKeyForm: this.createeInvoiceApiKeyForm(),
    };
  },

  validations: {
    eInvoiceApiKeyForm: {
      api_key: {
        required: rules.required,
      },
    },
  },

  watch: {
    value(val) {
      if (!val) {
        this.eInvoiceApiKeyForm = this.createeInvoiceApiKeyForm();
      }
    },
  },

  methods: {
    createeInvoiceApiKeyForm() {
      return new CodForm(this, 'eInvoiceApiKeyForm', {
        api_key: null,
      })
    },

    async save() {
      if (!this.eInvoiceApiKeyForm.validate()) {
        return
      }
      await ApiService.patch(`/admin/tenants/${this.curTenant.id}/update-api-key`, {
        api_key: this.eInvoiceApiKeyForm.api_key,
      }).then(() => {
        this.$snackbar('Api key updated', 'success');
        this.$emit('suceess');
        this.$emit('input', false);
      }).catch(($error) => {
        this.$snackbar($error.response.data.message, 'error');
      });

    },
  },
};
</script>
